import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { AvatarLarge, Button } from 'components';
import Basic_List_Icon1 from '../../../assets/Listing_Page/edit.svg';
import Basic_List_Icon2 from '../../../assets/Listing_Page/users.svg';
import Basic_List_Icon3 from '../../../assets/Listing_Page/rotate-cw.svg';
import Basic_List_Icon4 from '../../../assets/Listing_Page/message-square.svg';
import { convertMoneyToNumber, getRefCurrencyValue } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { currencyDB } from 'lib/currency-list';

const { Money } = sdkTypes;

const SubTabSectionDetailsContent = (props) => {
  const {
    avatarDetails,
    currentListing,
    intl,
    tabName,
    tabIndex,
    handlePackagePurchase,
    isDraftView,
    currencyConversionRate,
    ...rest
  } = props;
  const { to: aimedCurrency = 'USD', rate = 1 } = currencyConversionRate;
  const refCurrencyShow = aimedCurrency !== 'USD';
  const { publicData } = currentListing.attributes;
  const totalItineraryItems = (publicData?.packages[tabIndex]?.items) || 3;
  const totalParticipants = (publicData?.packages[tabIndex]?.participants) || 2;
  const deliveryHours = (publicData?.packages[tabIndex]?.deliveryTime) || 42;
  const collaborationHours = (publicData?.packages[tabIndex]?.collaborationTime) || 24;
  const listingCurrency = publicData.base_currency || 'USD';
  const packagePrice = publicData?.packages[tabIndex]?.price;
  const price = packagePrice ? new Money(packagePrice.amount, packagePrice.currency) : null;
  const estimatedRefPrice = getRefCurrencyValue(intl, rate, price);
  const refCurrencySign = currencyDB.find(c => c.value === aimedCurrency)?.sign;
  const priceInNumber = price ? convertMoneyToNumber(price) : '15';

  const { tripLength } = publicData?.packages[tabIndex] ?? {};
  const tripLengthLabel = tripLength ? tripLength.join(' - '): '';

  const handleSubmit = (e) => {
    if (isDraftView) {
      e.preventDefault();
    } else {
      handlePackagePurchase({
        ...publicData?.packages[tabIndex],
        price,
        packageLable: tabName,
      })
    }
  }

  const BasicItemList = [
    {
      id: 'list1',
      title: `${tripLengthLabel} day trips**`,
      src: Basic_List_Icon1,
    },
    {
      id: 'list2',
      title: `Up to ${totalParticipants} people`,
      src: Basic_List_Icon2,
    },
    {
      id: 'list3',
      title: `${deliveryHours}hr delivery`,
      src: Basic_List_Icon3,
    },
    {
      id: 'list4',
      title: `${collaborationHours}hr collaboration`,
      src: Basic_List_Icon4,
    },
  ];

  return (
    <div className={css.subTabSectionContainer}>
      <div>
        <div className={css.userInformation}>
          <div className={css.packageHeading}>RESEARCHED ITINERARY</div>
          <div className={css.userInformationWrap}>
            <div className={css.userListItem}>
              {BasicItemList.map(list => (
                <li key={list.id}>
                  <img src={list.src} alt={list.id} />
                  <h5>{list.title}</h5>
                </li>
              ))}
            </div>
            <div className={css.authorDetailsImageWrapper}>
              <div className={css.authorDetailsImageRelative}>
                <AvatarLarge
                  className={css.authorDetailsImageLarge}
                  user={avatarDetails}
                  disableProfileLink
                />
              </div>
              <div className={css.supContent}>
                {listingCurrency}
                <span>{`$${priceInNumber}`}</span>
                <span>{`*`}</span>
              </div>
              {refCurrencyShow && (
                <div
                  className={css.refCurrencyContent}
                >{`(${aimedCurrency} ~${refCurrencySign}${estimatedRefPrice})`}</div>
              )}
            </div>
          </div>
          <div className={css.bottomText}>
            <p>{`**Up to ${totalItineraryItems} research items included in this package`}</p>
            <p>
              *Pricing is displayed without taxes which may be applicable at checkout depending on
              purchase region.
            </p>
          </div>
        </div>

        <div className={css.subTabBtnWrap}>
          <Button rootClassName={css.sendRequestButton} onClick={handleSubmit}>
            Start Request
          </Button>
        </div>
      </div>
      <h3>WHAT YOU GET</h3>
      <p>
        This Trip Expert request will gather general ideas such as where, when, who with, budget,
        preferences and what you would like included. I will then research and craft a personalised
        itinerary, delivered online through the Lyfshort App within the quoted delivery time.
        Following delivery you have collaboration time to request adjustments and modifications
        using messaging. The service will finish when you acknowledge completion or the collaboration
        window expires.
      </p>
      <p>
        <span className={css.boldBlackText}>Bookings are not included in this price</span>, only
        research and itinerary construction, but after completion, you will have the option to book
        and pay for some or all of the items with me as a{' '}
        <span className={css.boldBlackText}>certified Travel Agent,</span> continuing to communicate
        together through the App.
      </p>
    </div>
  );
};

export default SubTabSectionDetailsContent;
