import moment from 'moment';
import { PERIODS } from 'components/IncomeContent/IncomeContent';
import { round } from 'lodash/math';
import config from 'config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  TRANSITION_COMPLETE,
  TRANSITION_EXPIRED_FROM_DELIVERED_1,
  TRANSITION_OP_COMPLETE,
} from 'util/transaction';

const { Money } = sdkTypes;

export const getPeriodDates = (currentUserCreatedDate, periodKey, currentDateObject, step) => {
  const currentDate = currentDateObject.clone();
  let modifiedDate = currentDate.clone();

  switch (periodKey) {
    case PERIODS.WEEK.key:
      modifiedDate.add(step, 'week');
      return {
        periodStart: modifiedDate.startOf('week').toDate(),
        periodEnd: modifiedDate.endOf('week').toDate(),
      };
    case PERIODS.MONTH.key:
      modifiedDate.add(step, 'month');
      return {
        periodStart: modifiedDate.startOf('month').toDate(),
        periodEnd: modifiedDate.endOf('month').toDate(),
      };
    case PERIODS.YEAR.key:
      modifiedDate.add(step, 'year');
      return {
        periodStart: modifiedDate.startOf('year').toDate(),
        periodEnd: modifiedDate.endOf('year').toDate(),
      };
    case PERIODS.ALLTIME.key:
      return {
        periodStart: moment(currentUserCreatedDate).toDate(),
        periodEnd: currentDate.toDate(),
      };
    default:
      modifiedDate = modifiedDate.add(step, 'month');
      return {
        periodStart: modifiedDate.startOf('month').toDate(),
        periodEnd: modifiedDate.endOf('month').toDate(),
      };
  }
};

export const sortByDate = (a, b) => {
  return a.attributes.createdAt - b.attributes.createdAt;
};

export const dataByUniqDate = (period, acc, tx) => {
  const amount = tx.attributes.metadata.amountPaid
    ? tx.attributes.metadata.amountPaid / 100
    : tx.attributes.payoutTotal.amount / 100;
  let label = null;
  switch (period.key) {
    case PERIODS.WEEK.key:
      label = moment(getCompletedTransitionDate(tx)).format('ddd');
      break;
    case PERIODS.MONTH.key:
      label = moment(getCompletedTransitionDate(tx)).format('DD MMM');
      break;
    case PERIODS.YEAR.key:
      label = moment(getCompletedTransitionDate(tx)).format('MMM');
      break;
    case PERIODS.ALLTIME.key:
      label = moment(getCompletedTransitionDate(tx)).format('MMM');
      break;
    default:
      label = moment(getCompletedTransitionDate(tx)).format('DD MMM');
  }

  acc[label] = acc[label] ? round(acc[label] + amount, 2) : amount;
  return acc;
};

export const intoChartData = (acc, item) => {
  const label = item[0];
  const data = item[1];
  return [
    [...acc[0], label],
    [...acc[1], data],
  ];
};

export const addMissingDates = (data, period, periodStart, currentDateObject) => {
  let i = 7;
  let dateStartObject = null;
  let listOfDates = [];
  /*eslint default-case: 0*/
  switch (period.key) {
    case PERIODS.WEEK.key:
      i = 7;
      dateStartObject = moment(periodStart).weekday(1);
      listOfDates.push(dateStartObject.format('ddd'));
      do {
        listOfDates.push(dateStartObject.add(1, 'day').format('ddd'));
      } while (listOfDates.length < i);
      return listOfDates.map(date => {
        if (data[date]) {
          return [date, data[date]];
        }
        return [date, null];
      });

    case PERIODS.MONTH.key:
      dateStartObject = moment(periodStart);
      i = currentDateObject.clone().daysInMonth();
      // add current date
      listOfDates.push(dateStartObject.format('DD MMM'));
      // add all the other dates
      do {
        listOfDates.push(dateStartObject.add(1, 'day').format('DD MMM'));
      } while (listOfDates.length < i);

      return listOfDates.map(date => {
        if (data[date]) {
          return [date, data[date]];
        }
        return [date, null];
      });
    case PERIODS.YEAR.key:
      dateStartObject = moment(periodStart);
      i = 12;
      listOfDates.push(dateStartObject.format('MMM'));
      do {
        listOfDates.push(dateStartObject.add(1, 'month').format('MMM'));
      } while (listOfDates.length < i);

      return listOfDates.map(date => {
        if (data[date]) {
          return [date, data[date]];
        }
        return [date, null];
      });
    case PERIODS.ALLTIME.key:
      dateStartObject = moment(periodStart);
      /**
       * Ceil the diff result between start and end of the period to include last month.
       * Otherwise, it will be skipped because diff(<date>, <unit>, false) will return
       * a floored integer.
       */
      i = Math.ceil(currentDateObject.diff(dateStartObject, 'months', true));

      listOfDates.push(dateStartObject.format('MMM'));
      do {
        listOfDates.push(dateStartObject.add(1, 'month').format('MMM'));
      } while (listOfDates.length <= i);

      return listOfDates.map(date => {
        if (data[date]) {
          return [date, data[date]];
        }
        return [date, null];
      });
  }
};

export const getPeriodTotalGross = initChartData => {
  const dataArray = Object.entries(initChartData);
  const totalPeriodGrossNumber = dataArray.reduce((acc, [key, amount]) => {
    return acc + amount * 100;
  }, 0);
  return new Money(totalPeriodGrossNumber, config.currency);
};

export const getCompletedTransitionDate = tx => {
  const completeTransition = tx.attributes.transitions.find(transition =>
    [TRANSITION_OP_COMPLETE, TRANSITION_COMPLETE, TRANSITION_EXPIRED_FROM_DELIVERED_1].some(
      completeTransition => completeTransition === transition.transition
    )
  );
  return completeTransition?.createdAt;
};
