import React, { Component, useState, useRef } from 'react';
import { string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import Slider from 'react-slick';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, UserDisplayName, SectionLevel } from '../../components';
import { priceData, convertMoneyToNumber, getRefCurrencyValue } from 'util/currency';
import { getRatingFromReviews } from 'containers/ListingPage/helper';
import { limitTextLength, sortImagesFunction } from './utils';
import css from './ListingCard.css';
import StarRatings from 'react-star-ratings';
import { validateStringLength } from './utils';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { currencyDB } from 'lib/currency-list';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    intl,
    listing,
    renderSizes,
    setActiveListing,
    reviews = [],
    viewButtonClass,
    authorNameClass,
    startingAtClass,
    cardContainerClass,
    imageWrapperClass,
    addressWrapperClass,
    reviewsWrapperClass,
    descriptionClass,
    buttonWrapperClass,
    favListings,
    setFavListingsChanged,
    currencyConversionRate,
  } = props;
  const viewButton = classNames(viewButtonClass, css.viewButton);
  const authorName = classNames(authorNameClass, css.authorName);
  const startingAt = classNames(startingAtClass, css.startingAt);
  const cardContainer = classNames(cardContainerClass, css.popular);
  const imageWrapper = classNames(imageWrapperClass, css.imageWrapper);
  const addressWrapper = classNames(addressWrapperClass, css.addressWrapper);
  const reviewsWrapper = classNames(reviewsWrapperClass, css.reviewsWrapper);
  const descriptionStyle = classNames(descriptionClass, css.description);
  const buttonWrapper = classNames(buttonWrapperClass, css.buttonWrapper);
  const currentListing = ensureListing(listing);
  const [isZoomIn, setZoomIn] = useState(false);
  const id = currentListing.id.uuid;
  const isFav = favListings && favListings.find(listing => listing.id.uuid === id);
  const [isFavourite, setIsFavourite] = useState(!!isFav);
  const sliderRef = useRef(null);
  const sliderSettings = {
    fade: true,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2100,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 0,
          arrows: false,
        },
      },
    ],
  };

  const { title = '', publicData, price, metadata, state } = currentListing.attributes;
  const slug = createSlug(title);
  const images = currentListing.images ? currentListing.images : [];
  const featureImages = images.slice(1, 3);
  const description = publicData.tagline;
  const formattedPrice = price ? `$${convertMoneyToNumber(price)} USD` : `0 USD`;
  const author = currentListing.author;
  const userName = author.attributes.profile.displayName;
  const rating = getRatingFromReviews(reviews);
  const reviewcount = reviews ? reviews.length : 0;

  const { address, context } = publicData.location ?? {};
  const formattedCountry = context?.country ? `, ${context?.country}` : '';
  const formattedPlace = context?.place ? `${validateStringLength(context?.place, 30)}, ` : '';
  const extraFormattedSlice =
    (context?.distract && `${validateStringLength(context?.distract, 30)}`) ||
    (context?.region && `${validateStringLength(context?.region, 30)}`) ||
    (context?.locality && `${validateStringLength(context?.locality, 30)}`) ||
    '';
  const currentLocation = formattedPlace + extraFormattedSlice + formattedCountry;
  const authorListingAddress = context ? currentLocation : address;

  const { to: aimedCurrency = 'USD', rate = 1 } = currencyConversionRate ?? {};

  const referenceCurrencySign = currencyDB.find(c => c.value === aimedCurrency)?.sign;
  const showingRefCurrency = aimedCurrency !== 'USD';
  const formattedReferencePrice = getRefCurrencyValue(intl, rate, price);
  const handleFavouriteListings = async action => {
    let updatedListingsArray = favListings.map(listing => listing.id.uuid);

    if (action === 'add') {
      if (!updatedListingsArray.includes(id)) {
        updatedListingsArray.push(id);

        try {
          await sdk.currentUser.updateProfile({
            privateData: {
              favListingsArray: updatedListingsArray,
            },
          });

          setIsFavourite(true);
          setFavListingsChanged(state => !state);
        } catch (e) {
          throw new Error(e);
        }
      }
    } else {
      updatedListingsArray = updatedListingsArray.filter(listingId => listingId !== id);

      try {
        await sdk.currentUser.updateProfile({
          privateData: {
            favListingsArray: updatedListingsArray,
          },
        });

        setIsFavourite(false);
        setFavListingsChanged(state => !state);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className={css.listingLink}>
      {state !== 'closed' ? (
        <div
          className={cardContainer}
          onMouseOver={() => {
            sliderRef.current.slickNext();
            sliderRef.current.slickPlay();
            setZoomIn(true);
          }}
          onMouseOut={() => {
            sliderRef.current.slickPause();
            sliderRef.current.slickPrev();
            setZoomIn(false);
          }}
        >
          <NamedLink name="ListingPage" params={{ id, slug }}>
            {favListings && (
              <div className={css.likeButton}>
                <div className={css.favButtonWrapper}>
                  {!isFavourite ? (
                    <FavoriteBorderIcon
                      onClick={() => handleFavouriteListings('add')}
                      className={css.favButtonNotSelected}
                    />
                  ) : (
                    <FavoriteIcon
                      onClick={() => handleFavouriteListings('remove')}
                      className={css.favButtonSelected}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              className={css.threeToTwoWrapper}
              onMouseEnter={() => setActiveListing(currentListing.id)}
              onMouseLeave={() => setActiveListing(null)}
            >
              {featureImages ? (
                <Slider
                  ref={sliderRef}
                  {...sliderSettings}
                  autoplay={isZoomIn}
                  className={css.slider}
                >
                  {featureImages.map(image => (
                    <div key={image.id.uuid} className={imageWrapper}>
                      <div className={css.aspectWrapper}>
                        <LazyImage
                          rootClassName={css.rootForImage}
                          alt={title}
                          className={classNames(css.popularImage, {
                            [css.popularImageHover]: isZoomIn,
                          })}
                          image={image}
                          variants={['scaled-small', 'square-small2x', 'landscape-crop']}
                          sizes={renderSizes}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : null}
            </div>
            <div style={{ position: 'relative' }}>
              <div className={startingAt}>
                <div>
                  from <span>{formattedPrice}</span>
                </div>
                {showingRefCurrency && (
                  <div className={css.referencePriceClass}>
                    {`~${referenceCurrencySign}${formattedReferencePrice} ${aimedCurrency}`}
                  </div>
                )}
              </div>

              <div className={css.cardDetailsWrapper}>
                <UserDisplayName className={authorName} user={author} intl={intl} />
                <div className={addressWrapper}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_173_866)">
                        <path
                          d="M10.5 5.21875C10.5 8.71875 6 11.7188 6 11.7188C6 11.7188 1.5 8.71875 1.5 5.21875C1.5 4.02528 1.97411 2.88068 2.81802 2.03677C3.66193 1.19286 4.80653 0.71875 6 0.71875C7.19347 0.71875 8.33807 1.19286 9.18198 2.03677C10.0259 2.88068 10.5 4.02528 10.5 5.21875Z"
                          stroke="#C13750"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6.71875C6.82843 6.71875 7.5 6.04718 7.5 5.21875C7.5 4.39032 6.82843 3.71875 6 3.71875C5.17157 3.71875 4.5 4.39032 4.5 5.21875C4.5 6.04718 5.17157 6.71875 6 6.71875Z"
                          stroke="#C13750"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_173_866">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0 0.21875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <span className={css.address}>{authorListingAddress}</span>
                  </div>
                </div>

                <div className={reviewsWrapper} style={{ opacity: reviewcount > 0 ? 1 : 0 }}>
                  <div className={css.reviersCountRating}>
                    <StarRatings
                      rating={rating}
                      starRatedColor="#346bf6"
                      starEmptyColor="#fff"
                      numberOfStars={1}
                      starDimension="16px"
                      starSpacing="0px"
                    />
                    <span className={css.ratingWrapper}>{rating}</span>
                  </div>
                  <div className={css.reviersCount}>{`(${reviewcount})`}</div>
                </div>

                <p className={descriptionStyle}>{limitTextLength(description, 100)}</p>

                <div className={buttonWrapper}>
                  <div className={viewButton}>
                    View
                  </div>
                </div>
              </div>
            </div>
          </NamedLink>
        </div>
      ) : (
        <div className={css.placeholderContainer}>
          {favListings && (
            <div className={css.likeButton}>
              <div className={css.favButtonWrapper}>
                {!isFavourite ? (
                  <FavoriteBorderIcon
                    onClick={() => handleFavouriteListings('add')}
                    className={css.favButtonNotSelected}
                  />
                ) : (
                  <FavoriteIcon
                    onClick={() => handleFavouriteListings('remove')}
                    className={css.favButtonSelected}
                  />
                )}
              </div>
            </div>
          )}
          <div className={css.cardNamePlaceholder}>{userName}</div>
          <div className={css.cardDetailsPlaceholder}>
            <p>Not currently taking orders, check back later.</p>
          </div>
        </div>
      )}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
