import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { string, bool, node } from 'prop-types';
import moment from 'moment';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldCurrencyInput,
  FieldSelect,
  FieldTextInput,
  LocationAutocompleteInputField,
  HeadingFormFieldWrapper,
} from '../../components';
import * as validators from '../../util/validators';
import config from '../../config';
import { currencyConfiguration } from '../../currency-config';

import css from './CheckoutTripForm.css';

const textRequired = value => (value ? undefined : 'Required');
const identity = v => v;

const CheckoutTripFormComponent = forwardRef((props, ref) => {
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current()
      }
    }
  }));

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          handleSubmit,
          onChange,
          inProgress,
          intl,
          listing,
          values,
          onBack,
        } = fieldRenderProps;

        formRef.current = handleSubmit;
        
        const baseCurrency = listing?.attributes?.publicData?.base_currency || config.currencyConfig;

        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });
        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );

        const minPriceLimit = validators.priceMustBeLower(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceMustBeLower',
          }),
          values.estimatedMaxBudget
        );

        const minPriceValidator = validators.composeValidators(priceRequired, minPriceLimit);

        //Living In
        const tripLocationLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripLocationLabel',
        });
        const originLocationLabel = intl.formatMessage({
          id: 'CheckoutTripForm.originLocationLabel',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'CheckoutTripForm.addressNotRecognized',
        });

        //Trip
        const tripLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripLabel',
        });
        const tripSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.tripSupport',
        });

        const tripNameLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripNameLabel',
        });

        const tripTypeLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripTypeLabel',
        });

        const tripTypeRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.tripTypeRequired',
        });

        //Where
        const whereLabel = intl.formatMessage({
          id: 'CheckoutTripForm.whereLabel',
        });
        const whereSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.whereSupport',
        });

        //Precision
        const precisionLabel = intl.formatMessage({
          id: 'CheckoutTripForm.precisionLabel',
        });
        const precisionRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.precisionRequired',
        });

        //When
        const whenLabel = intl.formatMessage({
          id: 'CheckoutTripForm.whenLabel',
        });
        const whenSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.whenSupport',
        });

        //Target Start Date
        const targetStartDateLabel = intl.formatMessage({
          id: 'CheckoutTripForm.targetStartDateLabel',
        });

        //Target End Date
        const targetEndDateLabel = intl.formatMessage({
          id: 'CheckoutTripForm.targetEndDateLabel',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        // Guidance
        const guidanceLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.guidanceLabel',
        });
        const guidanceSupportMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.guidanceSupport',
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy onChange={onChange} />
            <HeadingFormFieldWrapper
              label={tripLabel}
              supportText={tripSupportMessage}
              className={css.sectionTitle}
            />

            <FieldTextInput
              id="tripName"
              name="tripName"
              className={css.field}
              type="text"
              label={tripNameLabel}
              placeholder={tripNameLabel}
              validate={textRequired}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="tripType"
              id="tripType"
              label={tripTypeLabel}
              validate={validators.required(tripTypeRequiredMessage)}
            >
              <option value="" disabled>
                Select Trip Type
              </option>
              <option value="Open to Anything">Open to Anything</option>
              <option value="Learn & Explore">Learn & Explore</option>
              <option value="Relax & Recharge">Relax & Recharge</option>
              <option value="Escape & Reflect">Escape & Reflect</option>
              <option value="Have an Adventure">Have an Adventure</option>
              <option value="Celebration Event">Celebration Event</option>
              <option value="Socialise & Experience">Socialise & Experience</option>
              <option value="Work">Work</option>
              <option value="Visit Someone">Visit Someone</option>
              <option value="Family Getaway">Family Getaway</option>
            </FieldSelect>

            <div className={css.currencyWrapper}>
              <FieldCurrencyInput
                id="estimatedMinBudget"
                name="estimatedMinBudget"
                label={'Min Budget'}
                className={css.field}
                currencyConfig={currencyConfiguration(baseCurrency)}
                validate={minPriceValidator}
              />

              <FieldCurrencyInput
                id="estimatedMaxBudget"
                name="estimatedMaxBudget"
                label={'Max Budget'}
                className={css.field}
                currencyConfig={currencyConfiguration(baseCurrency)}
                validate={priceRequired}
              />
            </div>

            <HeadingFormFieldWrapper
              label={whereLabel}
              supportText={whereSupportMessage}
              className={css.sectionTitle}
            />

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autoFocus
              name="tripLocation"
              label={tripLocationLabel}
              placeholder={tripLocationLabel}
              useDefaultPredictions={false}
              format={identity}
              locationTypes={['locality', 'district', 'place', 'country', 'region', 'country']}
              valueFromForm={values.tripLocation}
              validate={validators.composeValidators(
                validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="precision"
              id="precision"
              label={precisionLabel}
              validate={validators.required(precisionRequiredMessage)}
            >
              <option value="" disabled>
                {'Select Geographic Precision'}
              </option>
              <option value="continent">{'Around Continent'}</option>
              <option value="country">{'Around Country'}</option>
              <option value="place">{'Around Place/City/State/Town'}</option>
            </FieldSelect>

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autoFocus
              name="originLocation"
              label={originLocationLabel}
              placeholder={originLocationLabel}
              useDefaultPredictions={false}
              format={identity}
              locationTypes={['locality', 'district', 'place']}
              valueFromForm={values.originLocation}
              validate={validators.composeValidators(
                validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <HeadingFormFieldWrapper
              label={whenLabel}
              supportText={whenSupportMessage}
              className={css.sectionTitle}
            />

            <FieldTextInput
              id="targetStartDate"
              name="targetStartDate"
              className={css.field}
              type="date"
              label={targetStartDateLabel}
              placeholder={targetStartDateLabel}
              min={moment().format('YYYY-MM-DD')}
              validate={textRequired}
            />

            <FieldTextInput
              id="targetEndDate"
              name="targetEndDate"
              className={css.field}
              type="date"
              label={targetEndDateLabel}
              placeholder={targetEndDateLabel}
              min={values.targetStartDate || moment().format('YYYY-MM-DD')}
              validate={textRequired}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="timePrecision"
              id="timePrecision"
              label={precisionLabel}
              validate={validators.required(precisionRequiredMessage)}
            >
              <option value="" disabled>
                {'Select Timing Precision'}
              </option>
              <option value="Exactly">{'Exactly'}</option>
              <option value="Few days either side">{'Few days either side'}</option>
              <option value="Totally Flexible">{'Totally Flexible'}</option>
            </FieldSelect>

            <HeadingFormFieldWrapper
              label={guidanceLabel}
              supportText={guidanceSupportMessage}
              className={css.sectionTitle}
            />

            <FieldTextInput
              id="guidance"
              name="guidance"
              className={css.field}
              type="textarea"
              label={intl.formatMessage({
                id: 'CheckoutDetailsForm.needToKnowThings',
              })}
              placeholder={guidanceSupportMessage}
            />
            <div className={submitButtonWrapperClassName}>
              <PrimaryButton type="button" onClick={onBack}>
                <FormattedMessage id="CheckoutDetailsForm.back" />
              </PrimaryButton>

              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="CheckoutDetailsForm.next" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
});

CheckoutTripFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

CheckoutTripFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckoutTripForm = CheckoutTripFormComponent;

CheckoutTripForm.displayName = 'CheckoutTripForm';

export default CheckoutTripForm;
