import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { Form, Button, Modal } from '../../components';
import config from '../../config';

import css from '../EditBusinessViewDraftForm/EditBusinessViewDraftForm.css';
import { filterListingsByCategory } from 'util/data';
import memoize from 'lodash/memoize';
import { richText } from 'util/richText';
import { userDisplayNameAsString } from 'util/data';
import { get } from 'util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import ListingPageCommonSection from 'containers/ListingPage/ListingPageCommonSection';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { routeConfiguration } from 'index';
import { createResourceLocatorString } from 'util/routes';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const { UUID } = sdkTypes;

const identity = v => v;
const filterListingsByCategoryMemo = memoize(filterListingsByCategory);

const TITLE_MAX_LENGTH = 60;
const PROVIDER_MAX_LENGTH = 30;
const YEARS_MAX_LENGTH = 3;

const VisibleSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00ab99' : '#00ab99',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#00ab99',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'grey' : 'grey',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

class EditListingViewDraftFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
      businessAccountInfo: null,
      isOpen: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.onContinue = this.onContinue.bind(this);
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);

    sdk.currentUser
      .show()
      .then(res => {
        const businessId = res.data.data.attributes.profile.metadata.businessId;
        get('/api/provider-business/get-public', { id: businessId }).then(resp => {
          const businessListingId = resp.providerBusiness.sharetribeListingId;
          const id = new UUID(businessListingId);
          return sdk.listings
            .show({
              id: id,
              include: ['images'],
              'fields.image': ['variants.scaled-small', 'variants.scaled-small2x'],
            })
            .then(res => {
              const businessAccountListing = res.data.data;
              const imageIds = businessAccountListing.relationships.images.data.map(
                item => item.id.uuid
              );
              const filteredImages = res.data.included.filter(image =>
                imageIds.includes(image.id.uuid)
              );

              const updatedBusinessAccountListing = {
                ...businessAccountListing,
                images: filteredImages,
                relationships: undefined,
              };

              this.setState(prevState => ({
                ...prevState,
                businessAccountInfo: updatedBusinessAccountListing,
              }));
            });
        });
      })
      .catch(e => console.log(e));
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  handleOpen() {
    this.setState({ isOpen: true });
  }

  onContinue() {
    const { history } = this.props;
    this.setState({ isOpen: false });
    window.open(createResourceLocatorString('OrderDeliveryGuidePage', routeConfiguration(), {}));
  }

  render() {
    const { listingType, currentListing, listingBackgroundImages, currencyConversionRate } = this.props;
    const { publicData, description, metadata, title, state } = currentListing.attributes;
    const isDraft = state === 'draft';
    const listingVisible = state === 'published';

    const handleBookingSubmit = e => {};
    const handlePackagePurchase = e => {};

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            subCategory,
            className,
            disabled,
            ready,
            handleSubmit,
            onChange,
            intl,
            invalid,
            pristine,
            publishActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            currentUser,
            viewport,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const businessAccountInfo = this.state.businessAccountInfo;

          // const reviews = [
          //   {
          //     id: {
          //       _sdkType: 'UUID',
          //       uuid: '6231a24f-7098-4383-ba56-d44d7304ed14',
          //     },
          //     type: 'review',
          //     attributes: {
          //       type: 'ofProvider',
          //       state: 'public',
          //       deleted: false,
          //       createdAt: '2022-03-16T08:39:43.352Z',
          //       rating: 2,
          //       content:
          //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.Ever since the 1500s, when an unknown printer took a galley.Lorem Ipsum has been the industry's standard dummy text.Ever since the 1500s, when an unknown printer took a galley.Lorem Ipsum has been the industry's standard dummy text.Ever since the 1500s, when an unknown printer took a galley",
          //     },
          //     author: {
          //       attributes: {
          //         profile: {
          //           abbreviatedName: 'RS',
          //           displayName: 'Naoko',
          //           bio: 'bio1y',
          //           publicData: {
          //             countryCode: '91',
          //             phoneNumber: '91882261589',
          //           },
          //         },
          //         banned: false,
          //         deleted: false,
          //         createdAt: '2021-03-31T06:00:43.915Z',
          //       },
          //       id: {
          //         _sdkType: 'UUID',
          //         uuid: '6064100b-7ba0-47a6-9cdf-dc2f9ddb4523',
          //       },
          //       type: 'user',
          //       profileImage: {
          //         id: {
          //           _sdkType: 'UUID',
          //           uuid: '616febfb-6b01-4cc9-9519-3890f04481bf',
          //         },
          //         type: 'image',
          //         attributes: {
          //           variants: {
          //             'square-small2x': {
          //               height: 480,
          //               width: 480,
          //               url: Review_Profile,
          //               name: 'square-small2x',
          //             },
          //             'square-small': {
          //               height: 240,
          //               width: 240,
          //               url: Review_Profile,
          //               name: 'square-small',
          //             },
          //           },
          //         },
          //       },
          //     },
          //   },
          //   {
          //     id: {
          //       _sdkType: 'UUID',
          //       uuid: '6231a23d-439c-4572-be2b-9cc113084baf',
          //     },
          //     type: 'review',
          //     attributes: {
          //       type: 'ofProvider',
          //       state: 'public',
          //       deleted: false,
          //       createdAt: '2022-03-16T08:39:25.971Z',
          //       rating: 2,
          //       content:
          //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.Ever since the 1500s, when an unknown printer took a galley",
          //     },
          //     author: {
          //       attributes: {
          //         profile: {
          //           abbreviatedName: 'RS',
          //           displayName: 'Naoko',
          //           bio: 'bio1y',
          //           publicData: {
          //             countryCode: '91',
          //             phoneNumber: '91882261589',
          //           },
          //         },
          //         banned: false,
          //         deleted: false,
          //         createdAt: '2021-03-31T06:00:43.915Z',
          //       },
          //       id: {
          //         _sdkType: 'UUID',
          //         uuid: '6064100b-7ba0-47a6-9cdf-dc2f9ddb4523',
          //       },
          //       type: 'user',
          //       profileImage: {
          //         id: {
          //           _sdkType: 'UUID',
          //           uuid: '616febfb-6b01-4cc9-9519-3890f04481bf',
          //         },
          //         type: 'image',
          //         attributes: {
          //           variants: {
          //             'square-small2x': {
          //               height: 480,
          //               width: 480,
          //               url: Review_Profile,
          //               name: 'square-small2x',
          //             },
          //             'square-small': {
          //               height: 240,
          //               width: 240,
          //               url: Review_Profile,
          //               name: 'square-small',
          //             },
          //           },
          //         },
          //       },
          //     },
          //   },
          //   {
          //     id: {
          //       _sdkType: 'UUID',
          //       uuid: '6143491a-6b28-4f44-a561-b983dcc5d469',
          //     },
          //     type: 'review',
          //     attributes: {
          //       type: 'ofProvider',
          //       state: 'public',
          //       deleted: false,
          //       createdAt: '2021-09-16T13:39:38.971Z',
          //       rating: 5,
          //       content:
          //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.Ever since the 1500s, when an unknown printer took a galley",
          //     },
          //     author: {
          //       attributes: {
          //         profile: {
          //           abbreviatedName: 'RS',
          //           displayName: 'Naoko',
          //           bio: 'bio1y',
          //           publicData: {
          //             countryCode: '91',
          //             phoneNumber: '91882261589',
          //           },
          //         },
          //         banned: false,
          //         deleted: false,
          //         createdAt: '2021-03-31T06:00:43.915Z',
          //       },
          //       id: {
          //         _sdkType: 'UUID',
          //         uuid: '6064100b-7ba0-47a6-9cdf-dc2f9ddb4523',
          //       },
          //       type: 'user',
          //       profileImage: {
          //         id: {
          //           _sdkType: 'UUID',
          //           uuid: '616febfb-6b01-4cc9-9519-3890f04481bf',
          //         },
          //         type: 'image',
          //         attributes: {
          //           variants: {
          //             'square-small2x': {
          //               height: 480,
          //               width: 480,
          //               url: Review_Profile,
          //               name: 'square-small2x',
          //             },
          //             'square-small': {
          //               height: 240,
          //               width: 240,
          //               url: Review_Profile,
          //               name: 'square-small',
          //             },
          //           },
          //         },
          //       },
          //     },
          //   },
          // ];

          const getRemovedWithOutContentReview = reviews => {
            return reviews.filter(
              review => review?.attributes.content !== config.dummyReviewContent
            );
          };
          const backgroundImage = listingBackgroundImages.slice(0, 1);
          const featureImages = listingBackgroundImages.slice(1, 3);
          const extraImages = currentListing.images;

          const listingVariant = currentListing?.attributes?.publicData.listing_type;
          const richTitle = (
            <span>
              {richText(title, {
                longWordMinLength: 16,
                longWordClass: css.longWord,
              })}
            </span>
          );
          const authorDisplayName = userDisplayNameAsString(currentUser, '');

          const bookingTitle = (
            <FormattedMessage id={'ListingPage.bookingTitle'} values={{ title: richTitle }} />
          );

          // const imgVariants =['scaled-medium', 'scaled-large', 'default'];
          // const imgVariants =['default'];

          return (
            <Form
              className={classes}
              onSubmit={(values) => {
                if (isDraft) this.setState({ isOpen: true });
                handleSubmit(values);
              }}
            >
              <div className={css.publishListingWrapper}>
                <div className={css.optionalTitle}>
                  <h1 className={css.headingTitle}>View Draft</h1>
                </div>
                {!isDraft ? (
                  <FormControlLabel
                    control={
                      <VisibleSwitch
                        sx={{ mx: 3 }}
                        defaultChecked={listingVisible}
                        onChange={e => onChange(e.target.checked)}
                      />
                    }
                    label="Listing Visible"
                    labelPlacement="start"
                    style={{ width: '250px', color: 'white' }}
                  />
                ) : (
                  // <div className={css.switch}>
                  //   <h3 style={{ color: 'white' }}>Listing Visible</h3>
                  //   <FieldCheckbox
                  //     id={'listingVisible'}
                  //     name={'listingVisible'}
                  //     value={listingVisible}
                  //   />
                  // </div>
                  <div className={css.btnGroupWrapper}>
                    <Button
                      rootClassName={css.submitButton}
                      type="submit"
                      inProgress={false}
                      disabled={submitDisabled}
                      ready={submitReady}
                    >
                      {publishActionMsg}
                    </Button>
                  </div>
                )}
              </div>
              <ListingPageCommonSection
                currentListing={currentListing}
                listingBannerImage={backgroundImage[0]}
                featureImages={featureImages}
                extraImages={extraImages}
                title={title}
                viewport={viewport}
                currentUser={currentUser}
                publicData={publicData}
                intl={intl}
                handleBookingSubmit={handleBookingSubmit}
                handlePackagePurchase={handlePackagePurchase}
                bookingTitle={bookingTitle}
                authorDisplayName={authorDisplayName}
                metadata={metadata}
                reviews={[]}
                listingVariant={listingVariant}
                otherAuthorsListings={[]}
                handlePackagesModalOpen={this.handlePackagesModalOpen}
                getRemovedWithOutContentReview={getRemovedWithOutContentReview}
                businessAccountInfo={businessAccountInfo}
                isDraftView={true}
                currencyConversionRate={currencyConversionRate}
              />

              {isDraft && (
                <Button
                  rootClassName={css.listingDraft_submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {publishActionMsg}
                </Button>
              )}
              <Modal
                id="deliverGuideModal"
                isOpen={this.state.isOpen}
                onClose={e => {
                  this.setState({ isOpen: false });
                }}
                onManageDisableScrolling={() => {}}
                containerClassName={css.modalClass}
              >
                <div style={{ textAlign: 'center'}}>
                  <strong>Congratulations!</strong>
                  <p>
                    Your listing <strong>page is visible</strong> on the Marketplace.
                  </p>
                  <p>
                    Now review <strong>how to deliver</strong> an Assisted Trip Planning order
                  </p>
                  <Button type="button" onClick={this.onContinue}>
                    Continue
                  </Button>
                </div>
              </Modal>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingViewDraftFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingViewDraftFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  publishActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditListingViewDraftFormComponent);
