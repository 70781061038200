import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHitpay.css';

const IconHitpay = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg version="1.1" id="Layer_1" className={classes} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="2160.4 337.9 512 317.4" style={{'enableBackground': 'new 2160.4 337.9 512 317.4'}} >
      <g>
        <path fill="#232750" d="M2544.3,577.6c2-6.4,4-12.7,6.1-19.2c6.2-20.1,12.5-40.1,18.7-60.2c1.4-4.4,2.6-5.3,7.1-5.3
          c8.9,0,17.8,0,26.6,0c5.6,0,6.6,1.4,4.8,6.9c-2.5,7.5-5.2,15-7.9,22.5c-2.3,6.5-4.6,13-6.8,19.6c-0.4,1.1-0.5,2.3-0.1,3.5
          c3.7,10.6,7.4,21.3,11,31.9c0,0.1,0.2,0.2,0.5,0.4c2.1-3.6,3-7.5,4.4-11.3c8.2-22.7,16.2-45.5,24.3-68.3c1.6-4.5,2.5-5.2,7.2-5.2
          c8.7,0,17.4,0,26.2,0c5.1,0,6.3,1.5,4.4,6.4c-5.6,14.3-11.2,28.5-16.9,42.7c-10.9,26.7-21.9,53.3-32.2,80.2
          c-0.4,1.1-0.9,2.2-1.4,3.3c-1,2.3-2.7,3.5-5.3,3.5c-7.2,0-14.5,0-21.7,0c-3,0-4.7-1.4-5.6-4.2c-3.6-9.7-7.3-19.4-11-29
          c-0.7-1.9-1.5-3.9-2.3-6c-2,1.4-2.2,3.7-2.9,5.6c-3.3,8.5-6.3,17.2-9.5,25.7c-0.5,1.4-1.3,2.7-1.9,4.1c-1.2,2.5-3.2,3.8-6.1,3.7
          c-6.8-0.1-13.6-0.1-20.3,0c-2.9,0-4.7-1.2-6-3.8c-3.8-7.7-6.4-15.9-9.5-23.8c-11.4-29.5-22.6-59.2-33.9-88.8
          c-1.8-4.7-3.7-9.5-5.4-14.3c-1.4-3.9-0.4-5.4,3.7-5.4c9.3-0.1,18.7,0,28,0c3.2,0,5,1.4,6,4.4c1.6,5,3.3,10,5,15
          c7.3,21.7,14.6,43.5,22,65.2C2543.8,577.6,2544,577.6,2544.3,577.6z"/>
        <path fill="#232750" d="M2196.1,549.6c0,1.6,0,3.3,0,4.9c0,22.3,0,44.5,0,66.8c0,1,0,2.1,0,3.2c-0.3,3.4-1.3,4.5-4.8,4.6
          c-8.4,0.1-16.8,0.1-25.3,0c-3.5,0-4.5-1.1-4.8-4.6c-0.1-0.9,0-1.8,0-2.7c0-40.5,0-81,0-121.5c0-0.9,0-1.8,0-2.7
          c0.2-2.8,1.7-4.5,4.6-4.5c9.9-0.1,19.9,0,29.8,0c2.6,0,4.4,1.6,5.7,3.7c5.8,9.5,11.7,18.9,17.6,28.3c8.8,14,17.6,28,26.5,42.2
          c1.3-1.2,0.7-2.6,0.7-3.8c0-20.9,0-41.8,0-62.7c0-1.4-0.1-2.7,0.2-4.1c0.5-2.4,1.9-3.7,4.4-3.7c8.7-0.1,17.5-0.1,26.2,0
          c3.1,0,4.2,1.5,4.5,4.5c0.1,1,0,2.1,0,3.2c0,40.3,0,80.7,0,121c0,7.1-0.2,7.3-7.4,7.3c-7.8,0-15.7-0.2-23.5,0.1
          c-3.7,0.1-5.9-1.4-7.7-4.3c-4.9-8.3-9.9-16.5-14.9-24.7c-9.6-15.9-19.3-31.9-28.9-47.8c-0.6-1-1.4-1.9-2-2.9
          C2196.6,549.4,2196.3,549.5,2196.1,549.6z"/>
        <path fill="#232750" d="M2411.2,464c0-14.1,0.1-28.3,0.2-42.4c0-1.9-0.8-3.5-1.6-5.1c-12.4-22-24.8-43.9-37.3-65.9
          c-1.6-2.9-3.4-5.7-5-8.6c-0.9-1.8-0.4-2.9,1.7-3c3.3-0.1,6.6,0,9.9,0c7.5,0,15,0.1,22.6,0c3.1-0.1,5,1,6.3,3.9
          c5.2,11.5,10.7,22.9,16,34.3c1.6,3.5,3.3,7,5.3,11.2c3.8-8.1,7.3-15.4,10.7-22.9c3.4-7.4,6.9-14.7,10.2-22.1
          c1.4-3.2,3.3-4.5,6.9-4.4c9.9,0.3,19.9,0.1,29.8,0.1c0.8,0,1.5,0,2.3,0c2.4,0.3,2.9,1.1,1.7,3.2c-3.9,6.9-7.9,13.8-11.9,20.7
          c-9.9,17.5-19.8,35.1-29.8,52.5c-1.5,2.7-2.2,5.4-2.1,8.5c0.1,14.8,0,29.5,0,44.3c0,5.6-1,6.5-6.5,6.6c-2.6,0-5.1,0.1-7.7,0.1
          c-5.6-4.2-12.2-6.6-18.8-8.7C2412.9,466,2411.6,465.5,2411.2,464z"/>
        
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2290.5969" y1="642.5134" x2="2484.3013" y2="642.5134" gradientTransform="matrix(1 0 0 -1 0 1200.6001)">
          <stop  offset="0" style={{'stopColor': '#232750' }}/>
          <stop  offset="1" style={{'stopColor': '#232750' }}/>
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M2476.1,519.1c-5.9-13.4-14.5-24.8-25.5-34.6c-3.3-3-8.1-2.2-10.1,1.1c-1.4,2.3-3.2,4.4-4.7,6.5
          c-8.6,11.9-17.2,23.8-25.8,35.8c-7.4,9.9-14.7,19.9-22.1,29.9c-0.8-0.8-1.2-1-1.4-1.4c-8.2-10-16.4-20-24.5-29.9
          c-3.3-4.1-6.9-4.1-10,0c-3.7,4.9-7.3,9.9-10.8,15c-2.2,3.2-1.9,6.4,0.5,9.5c2.4,3.1,4.9,6.1,7.4,9.1c11.4,14,22.8,27.9,34.2,41.8
          c4.2,5.2,7.5,5.1,11.5-0.1c0.4-0.5,0.7-0.9,1.1-1.4c6.5-9,13.2-17.8,19.6-26.9c5.5-7.9,11.6-15.3,16.7-23.5
          c2.8-2.6,4.6-5.9,6.8-8.9c3.3-4.4,6.5-8.9,9.5-13c1.1,0,1.4,0.4,1.5,0.8c0.5,0.9,0.9,1.9,1.3,2.9c5.4,13.1,6.7,26.6,3.7,40.5
          c-1.3,4.1-2.2,8.3-3.9,12.2c-7.5,17.8-20.4,30.4-38.3,37.7c-3,1.3-6.1,2.3-9.3,3.4c-1.3,0.2-2.7,0.4-4,0.6
          c-8.2,1.3-16.4,1.5-24.6-0.1c-15.4-2.9-28.5-10.1-38.9-21.8c-11.3-12.6-17.3-27.4-17.8-44.4c-0.2-7.4,1.2-14.6,2.7-21.8
          c0.3-0.7,0.7-1.3,0.9-2c4.7-12.8,12.8-24.4,23.5-33c12.7-10.2,28.8-15,45-14.2c0.6,0,1.2,0,1.9,0.1c7.4,0.5,14.4,2.3,21.3,4.9
          c4.9,1.9,7.5,1.1,10.5-2.9c2.5-3.3,4.9-6.8,7.4-10.1c1.4-1.8,1.9-3.7,1.6-5.9c-1.4-2.3-3.4-3.8-6-5.1c-14.8-7.1-30-9.5-46.3-8.1
          c-24.7,2.1-47.2,11.8-64.1,30c-7,7.5-13,15.8-16.8,25.3c-0.2,0.2-0.4,0.5-0.5,0.7c-7.6,17-10.3,34.7-7.9,53.1
          c3.3,24,14.1,44.3,32.3,60.2c21.1,18.4,46.1,25.7,73.8,23.2c29.4-2.6,57.5-20.7,72.6-45.9c3.2-5.4,5.8-11.1,7.9-17
          c4.1-9.5,5.6-19.6,6.1-29.8C2484.8,546.9,2482.1,532.7,2476.1,519.1z"/>
        <path fill="#232750" d="M2374.2,490.1c0.8-0.9,1.8-0.7,2.8-0.6C2376.1,490,2375.2,490.1,2374.2,490.1z"/>
        <path fill="#232750" d="M2263.3,365.6c-6.4-16-21.6-27.1-39.1-26.6c-18.5,0.4-37,0.1-55.6,0.1c-1.2,0-2.4-0.1-3.6,0.1
          c-1.7,0.2-2.7,1.3-2.9,3c-0.1,1.2,0,2.4,0,3.6c0,20.5,0,41,0,61.4c0,20.8,0,41.6,0,62.4c0,4.2,0.8,5.3,4.2,5.4
          c9,0.1,18.1,0.1,27.1,0c3,0,4.2-1.4,4.5-4.5c0-0.9,0-1.8,0-2.7c0-12.2,0-24.4,0-36.6c0-3.4,0.1-3.5,3.5-3.5c7.8,0,15.7,0,23.5,0
          c12.2-0.2,22.8-4.7,30.7-14C2267.7,399.3,2270.2,383,2263.3,365.6z M2216,398.2c-2.3-0.2-4.5,0-6.8,0c-3,0-6-0.1-9,0
          c-1.8,0-2.3-0.7-2.3-2.4c0-8.3,0-16.5,0-24.8c0-1.9,0.5-2.7,2.5-2.7c5.9,0.1,11.7-0.1,17.6,0.1c5.5,0.1,10.7,4.9,12.2,11
          C2232.6,389.1,2225.5,398.9,2216,398.2z"/>
        <path fill="#232750" d="M2377.2,439.5c-9.2-23.8-18.4-47.6-27.6-71.4c-3-7.9-6.2-15.6-9.2-23.5c-1.5-4-3.9-6-8.4-5.7
          c-5.4,0.4-10.8,0.1-16.3,0.1c-8.7,0-8.7,0.1-11.7,8c-14.2,36.8-28.4,73.6-42.6,110.4c-1.7,4.3-3.4,8.7-4.9,13.1
          c-0.9,2.5-0.1,3.7,2.3,4.2c0.7,0.1,1.5,0.1,2.3,0.1c8.1,0,16.3-0.1,24.4,0c3.6,0,5.6-1.5,6.7-4.8c1.7-4.8,3.7-9.5,5.4-14.3
          c0.8-2.1,1.9-3,4.2-3c14,0.1,28,0.1,42,0c2.4,0,3.5,1,4.2,3.1c1.2,3.4,2.6,6.7,3.9,10.1c0.4,2.2,1.7,2.5,3.7,2
          c2.3-0.6,4.5-1.6,6.9-1.9c5.7-1.1,11.3-2.5,17.2-2.8c2.1-0.1,4.7,0,5.6-2.8C2382.5,453.5,2379.9,446.5,2377.2,439.5z M2309.4,422.8
          c4.3-13.2,8.5-26.1,12.7-39c5.5,12.6,9.4,25.6,14.1,39C2326.9,422.8,2318.4,422.8,2309.4,422.8z"/>
      </g>
      </svg>

  );
};

IconHitpay.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconHitpay.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconHitpay;
