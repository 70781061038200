import React from 'react';
import { txIsEnquired } from 'util/transaction';
import { ensureListing } from 'util/data';
import classNames from 'classnames';
import { BookingTimeInfo } from 'components/index';
import { DATE_TYPE_DATETIME, propTypes } from 'util/types';
import { intlShape } from 'util/reactIntl';
import { bool } from 'prop-types';

//import css from 'containers/InboxPage/InboxPage.css';
import css from './BookingInfoMaybe.css';

const BookingInfoMaybe = props => {
  const { bookingClassName, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }
  const listingAttributes = ensureListing(tx.listing).attributes;
  const timeZone = listingAttributes.availabilityPlan
    ? listingAttributes.availabilityPlan.timezone
    : 'Etc/UTC';

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        intl={intl}
        tx={tx}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  tx: propTypes.transaction.isRequired,
};

export default BookingInfoMaybe;
