import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from 'components';
import { TopbarContainer, StaticPage } from 'containers/index';
import { FormattedMessage } from 'react-intl';
import css from './DefinitionsPage.css';
import Collapsible from 'react-collapsible';

const termsList = [
  {
    term: 'Assisted Trip Planning',
    definition:
      'The process of hiring a Trip Expert from the Lyfshort marketplace, purchased as a set price Service as a Product (SaaP).   The Expert then conducts personalised research based on provided requirements, creates an interactive itinerary delivered in the Lyfshort organiser app, then collaborates through In App messaging for changes until complete within a set time period.',
  },
  {
    term: 'Collaboration Time Window',
    definition:
      'The period of time a Customer can submit requested modifications to an Interactive Itinerary delivered by a Trip Expert before it is complete.  Request details are communicated using In App messaging.  The time period is a specified inclusion as part of a standard SaaP, set by the Trip Expert and part of the agreement, clearly visible at time of purchase. <em>(Example - 24hr, 48hrs, 72hrs, 120hrs etc.)</em>',
  },
  {
    term: 'Customer',
    definition: 'Someone hiring a Trip Expert for Assisted Trip Planning.',
  },
  {
    term: 'Delivery Time Window',
    definition:
      'The period of time a Trip Expert commits to researching and delivering an Interactive Itinerary to the Customer within the Lyfshort organiser App, measured from time of order acceptance.  The time period is an inclusion as part of a standard SaaP, set by the Trip Expert and part of the agreement, clearly visible at time of purchase.  <em>(Example - 24hr, 48hrs, 72hrs, 120hrs etc.)</em>',
  },
  {
    term: 'In App Messaging',
    definition:
      'Direct and Group Messaging is available within the Lyfshort organiser App.  It can be used from the Messaging Centre in the App header or from within individual Interactive Itinerary items.  Communication such as text, images, PDF Documents and reactions are typically shared in conversations.  In App messaging is real-time, supports notifications and badges within the Lyfshort App and the main medium used to collaborate between the Customer and Trip Expert for any changes to Interactive Itineraries. ',
  },
  {
    term: 'Interactive Itinerary',
    definition:
      'An dynamic, online itinerary constructed in the Lyfshort App that can be viewed and shared between groups of Trip or Event participants.  4 roles types are supported, Owner, Trip Expert, Co-owner and Participant, each with slightly different access rights.  Lyfshort Interactive Itineraries are typically used to manage master Itineraries, either individually or between a group.  Key features include Import supplier bookings, invite and manage participants,  plan, track and split expenses, save and share photos and documents, location mapping, direct and group messaging.',
  },
  {
    term: 'Interactive Itinerary Roles',
    definition:
      '4 roles types are supported. Owner, Trip Expert, Co-owner and Participant, each with slightly different access rights.{br}{br} An OWNER is the initial creator of the Itinerary, has read and write access to everything, can invite anyone to the activities they created, allocate Co-owners and delete the activities they created. {br}{br} A CO-OWNER has similar rights to the Owner however cannot delete the Itinerary. Typically they are allocated to help the owner manage certain activities in the itinerary. There can be multiple Co-owners for each activity.  They are created by an Owner opening an existing participant listed in the “Group Participants” screen and changing the setting from “Participant” to “Co-owner”. {br}{br} A TRIP EXPERT has similar rights to a Co-owner however is hired by the Owner on the Trip Expert Marketplace to Assist in researching and creating a personalised Itinerary based on provided requirements, saving significant amounts of time supported with Expert travel industry knowledge. {br}{br} A PARTICIPANT is an invitee to Itinerary items.  They typically receive an invitation to participate, sent by an Owner or Co-owner through Lyfshort (push notification), email, SMS or social link.  A Participant can then review details like date, location, other linked itinerary activities, other participants and respond Yes/No or Maybe.  Participants cannot edit core activity details such as time, date, location, feature image, financial details etc. however they can send messages, upload and download images and save bookmarks within the group. Participants are unable to invite people to the private group. If they would like to do any of these things messaging can be used to request being upgraded to a Co-owner to the Owner. ',
  },
  {
    term: 'Interests',
    definition:
      'A range of personal interests nominated by a Trip Expert that can be used by a customer to filter profiles on the marketplace.',
  },
  {
    term: 'Maximum Number of itinerary items',
    definition:
      'Used as the primary key unit of measure within a SaaP when a Customer hires a Trip Expert.  It is set by the Trip Expert, displayed on their listing page as part of packages and is critical in defining (and limiting) the scope and amount of time an order will potentially take to deliver for a set price.',
  },
  {
    term: 'Maximum Number of Trip Participants',
    definition:
      'Used as second key unit of measure within a SaaP when a Customer hires a Trip Expert.  It is set by the Trip Expert, displayed on their listing page as part of packages and is critical in defining (and limiting) the scope and amount of time an order will potentially take to deliver for a set price.',
  },
  {
    term: 'Order Request',
    definition:
      'Submitted following the Order Process on the Marketplace where a Customer has selected a Trip Expert, provided requirement details and payment pre-authorisation and is awaiting acceptance.  Upon request acceptance by the Trip Expert the SaaP order delivery begins.',
  },
  {
    term: 'Organizer App',
    definition:
      'The Lyfshort organiser App available from the App store, Play store and on Web.  Typically used to manage master Itineraries, either individually or between a group. Key features include Import supplier bookings, invite and manage participants, plan, track and split expenses, save and share photos and documents, location mapping, direct and group messaging.  Is also the core communication and management tool used to deliver Assisted Trip Planning services on the Lyfshort Marketplace.',
  },
  {
    term: 'Partner Travel Business',
    definition:
      'Typically a registered Travel Agency.  Has completed an application, due diligence and approval process to become a marketplace Partner.  Has Business Administration access to invite associated Travel Agents to create Trip Expert profiles linked to their specific business.  All SaaP revenue from linked Trip Experts is deposited to the Partner Travel Businesses connected bank account.  It is then the Business administrators responsibility to distribute funds to Trip Experts based on marketplace reporting available through their login and internal agreements within their business.',
  },
  {
    term: 'Partner Travel Business link',
    definition:
      'The connection between a Trip Expert and the Partner Travel Business they are affiliated with on the marketplace.  This is setup when the Partner Travel Business administrator invites the Travel Agent to create their Trip Expert profile and SaaP packages.',
  },
  {
    term: 'Service as a Product (SaaP)',
    definition:
      'Trip Experts create sales listing profile pages on the Marketplace browsable by customers.  This includes customised, set pricing for a bundle of services they are available to deliver.  These bundle of services are know as a SaaP (Service as a Product), available for purchase.    Each sales listing includes 2 packaged and 1 custom SaaP, available for purchase, this is how a Trip Expert is hired. {br}{br} Each SaaP has 4 elements: {br}{br}1. Maximum Number of itinerary items {br}{br} Maximum number included in customised research and itinerary created. {br}{br} 2. Maximum Number of Trip Participants {br}{br} Maximum trip participants the research and itinerary covers. {br}{br} 3. Delivery Time Window {br}{br} Maximum time between acceptance of order and delivery of the Interactive Itinerary within the Lyfshort Organiser App. {br}{br} 4. Collaboration Time Window {br}{br} Maximum time limit a customer can message the Trip Expert to modify an Interactive Itinerary after delivery. (Once the Collaboration window expires, the order is automatically closed and complete).',
  },
  {
    term: 'Specialist Planning Regions',
    definition:
      'A range continents, countries, regions, districts, places, towns and cities Trip Experts can list as specialising in on their sales page.  Customers can then filter by these locations when searching for a suitable Expert for helping plan their trip.',
  },
  {
    term: 'Travel Agency',
    definition:
      'Typically a Partner Travel Business.  Once approved, a Travel Agency Business administrator can invite their Travel Agents to become Trip Experts on the marketplace, create a listing and sell SaaP services.  All SaaP based revenue generated by Trip Experts is deposited into their linked Travel Agency bank account.',
  },
  {
    term: 'Travel Agent',
    definition:
      'An accredited Travel specialist that has access to travel industry supplier booking and payment systems (Flights, accommodation, activities etc.), specialist travel knowledge and who is also affiliated or employed by a registered Travel Agency.  To be a Trip Expert on the marketplace a person must be an existing Travel Agent.',
  },
  {
    term: 'Travel Agent Service',
    definition:
      'Following completion of a SaaP transaction, the customer has the option to continue on and book some or all itinerary items directly with the Trip Expert in their capacity as a Travel Agent.  The conversation simply continues via messaging within the Lyfshort Interactive Itinerary App leading up to and during the trip. {br}{br} Lyfshort, as an independent marketplace facilitator, does not charge commissions, share revenue or margins or on travel bookings (Eg. flights, accommodation, activities etc.), however, does charge a nominal lead fee, directly associated with the booking opportunities created with every SaaP transaction, to the associated Travel Agency.',
  },
  {
    term: 'Travel Business Listing Page',
    definition:
      'Each Partner Travel Business creates and maintains a Travel Agency Listing page on the Marketplace displaying details transparently to potential customers to provide credibility and trust for their brand and associated Trip Experts on the marketplace.',
  },
  {
    term: 'Traveler',
    definition: 'Person looking to use Assisted Trip Planning as a customer.',
  },
  {
    term: 'Trip Expert',
    definition:
      'Will take customer ideas, research and craft personalised Interactive Itineraries, delivered and collaborated together with the traveler through the Lyfshort organiser App, for a published fee.  Trip Experts are also verified Travel Agents linked to Partner Travel Agencies so after SaaP delivery, customers can choose to either book & pay for some or all of their trip items (or go direct with suppliers).',
  },
  {
    term: 'Trip Expert Listing Page',
    definition:
      'Each Trip Expert creates and maintains a Sales Listing page on the Marketplace displaying details to potential customers showcasing what makes them a great choice to hire for expert assistance in planning certain types of trips to certain types of locations. This includes information such as background, experience, geography, language(s), interests, specialisations, photos, videos, ratings etc.  Sales listings also include pricing for 2 packaged and 1 custom SaaP (Service as a Product) options available for purchase, this is how a Trip Expert is hired.',
  },
  {
    term: 'Trip Expert Service',
    definition:
      'The process of Hiring a Trip Expert, for a set cost, who takes customers unique Trip ideas and crafts them into a personalised Interactive Itinerary based on purchased Service as a Product (SaaP) criteria, delivered within the Lyfshort Organiser App within a specified time frame.',
  },
  {
    term: 'Trip Type Specialties',
    definition:
      'A range of trip types nominated by a Trip Expert on their listing page that can be used by a customer to filter suitable profiles on the marketplace. (Examples : Beach, Adventure, Long Weekends, Weddings, Cruises etc.)',
  },
];

export default function DefinitionsPage() {
  return (
    <StaticPage title="Definitions">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.definitionsSection}>
            <div className={css.containerWrapper}>
              <h1>Marketplace Definitions</h1>
              {termsList.map((term, index) => (
                <Collapsible
                  key={index}
                  trigger={
                    <div className={css.expandableBar}>
                      <span>{term.term}</span>
                      <span className={css.accordionArrow}></span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.expandedBar}>
                      <span>{term.term}</span>
                      <span className={css.accordionArrowOpen}></span>
                    </div>
                  }
                >
                  <p>
                    <FormattedMessage
                      id={'sentence' + index}
                      defaultMessage={term.definition}
                      values={{
                        br: <br />,
                        em: chunks => <em>{chunks}</em>,
                      }}
                    />
                  </p>
                </Collapsible>
              ))}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}
