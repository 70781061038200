import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { useStripe, useElements, CardElement, AddressElement } from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';

import css from './ChargeCustomerForm.module.css';
import CardSection from '../CardSection/CardSection';
import BillingDetails from '../BillingDetails';
import { post } from 'util/api';
import stripePoweredIcon from '../../../assets/CheckoutPage/powered-by-stripe.svg';

const ChargeCustomerForm = props => {
  const { onSubmit, listing, pageData, speculatedTransaction } = props;
  const [stripe, setStripe] = useState(false);
  const [card, setCard] = useState(false);
  const [billingAddress, setBillingAddress] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [cardReady, setCardReady] = useState(false);
  const [addressReady, setAddressReady] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const authorId = listing?.author?.id?.uuid;
  const amountToPay = speculatedTransaction?.attributes?.payinTotal?.amount;
  const handleStripeToken = (event, stripe, elements) => {
    const card = elements.getElement(CardElement);
    if (event.complete) {
      setCardReady(true);
    }
    setCard(card);
    setStripe(stripe);
  };
  const handleStripeAddress = (event, stripe, elements) => {
    const address = elements.getElement(AddressElement);
    setAddressReady(!!event.complete);
    setBillingAddress(event.value);
  };

  const handleErrMsg = (code) => {
    switch (code) {
      case 'expired_card':
        return "Card Expired";
      case 'incorrect_cvc':
        return "Incorrect CVC";
      case 'incorrect_number':
        return "Incorrect Number";
      case 'processing_error':
        return "Processing Error";
      default:
        return "Processing Error";
    }
  }

  const handleDeclineErrMsg = (declineCode) => {
    switch (declineCode) {
      case 'generic_decline':
        return 'Card Declined';
      case 'insufficient_funds':
        return 'Insufficient Funds'
      case 'lost_card':
      case 'stolen_card':
        return 'Card Invalid';
      default:
        return 'Declined';
    }
  }

  const speculatedTxId = speculatedTransaction?.id?.uuid;
  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage("");

    const tokenData = {
      name: billingAddress.name,
      address_line1: billingAddress.address.line1,
      address_line2: billingAddress.address.line2 ?? '',
      address_city: billingAddress.address.city,
      address_state: billingAddress.address.state,
      address_zip: billingAddress.address.postal_code,
      address_country: billingAddress.address.country,
    }
    return stripe
      .createToken(card, tokenData)
      .then((resp) => {
        return onSubmit({
          stripeTokenId: resp.token.id,
          amountToPay,
          billingAddress,
        });
      })
      .catch(e => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  //   setLoading(false);
  //   setSubmitted(true);
  //   setSuccess(true);
  //   setError(false);
  // })
  // .catch(e => {
  //   setLoading(false);
  //   setError(true);
  //   console.log(e);
  // });

  const successMessage = 'Congratulations! Your payment was successful!';
  const submitDisabled = !cardReady || !addressReady;

  if (!amountToPay) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      <div className={css.paymentHeadingWrapper}>
        <h2 className={css.title}>Payment</h2>
        <div className={css.paymentHeadingIconWrapper}>
          <img
            src={stripePoweredIcon}
            alt="Powered by Stripe icon"
            className={css.stripeIcon}
          />
        </div>
      </div>
      <div className={css.cardSectionWrapper}>
        <CardSection handleStripeToken={handleStripeToken} setError={setError} setErrorMessage={setErrorMessage} />
      </div>
      <h2 className={css.title}>Billing Details</h2>
      <div className={css.cardSectionWrapper}>
        <BillingDetails handleStripeAddress={handleStripeAddress}/>
      </div>
      <Button
        type="button"
        onClick={handleSubmit}
        disabled={submitDisabled || submitted || loading}
      >
        {submitted ? (
          <CheckIcon />
        ) : loading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress className={css.spinnerMiddle} />
          </Box>
        ) : (
          'Send request'
        )}
      </Button>
      {error && <div className={css.errorMsg}>{errorMessage}</div>}
    </div>
  );
};

export default ChargeCustomerForm;
