import React from 'react';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from 'components';
import { TopbarContainer } from 'containers/index';
import css from './CommercialModelPage.css';

export default function CommercialModelPage() {
  return (
    <Page title="Commercial Model">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.commercialModelSection}>
            <div className={css.containerWrapper}>
              <h1>Commercial Model</h1>
              <div className={css.subContainterWrapper}>
                <h2 className={css.redFontClass}>Trip Expert Hire - Service as a Product (SaaP)</h2>
                <p>
                  Each Trip Expert sets customized SaaP inclusions and pricing which the research
                  and itinerary service is based on, in USD, displayed for sale on their marketplace
                  listing page. SaaP’s include 4 elements.
                </p>
                <div className={css.descriptionTable}>
                  <table>
                    <tr>
                      <th>Inclusion</th>
                      <th>Description</th>
                    </tr>
                    <tr>
                      <td>1. Maximum Number of itinerary items</td>
                      <td>Maximum number included in customized research and itinerary created.</td>
                    </tr>
                    <tr>
                      <td>2. Maximum Number of Trip Participants</td>
                      <td>Maximum trip participants the research and itinerary covers.</td>
                    </tr>
                    <tr>
                      <td>3. Delivery Time Window</td>
                      <td>
                        Maximum time between acceptance of order and delivery of the Interactive
                        Itinerary within the Lyfshort Organiser App.
                      </td>
                    </tr>
                    <tr>
                      <td>4. Collaboration Time Window</td>
                      <td>
                        Maximum time limit a customer can message the Trip Expert to modify an
                        Interactive Itinerary after delivery. (Once the Collaboration window
                        expires, the order is automatically closed and complete).
                      </td>
                    </tr>
                  </table>
                </div>
                <p>
                  Customers complete a Trip Expert request which includes nominating a SaaP package
                  accompanied by general ideas to base research on, such as where, when, who with,
                  budget and preferences submitted with hire payment. If the Trip Expert accepts the
                  order, delivery is sent through the Lyfshort Organiser App as an Interactive
                  Itinerary within the quoted delivery time. The customer then has collaboration
                  time to request and communicate adjustments and modifications using In-App
                  messaging. The service is complete when either the customer agrees or the
                  purchased collaboration window expires.
                </p>
                <div className={css.tableSection}>
                  <p>SaaP payment is distributed as follows:</p>
                  <table>
                    <tr>
                      <th>Sale Price Tiers(USD)</th>
                      <th>Marketplace Receives</th>
                      <th>Trip Expert Receives</th>
                    </tr>
                    <tr>
                      <td>$0 to $25</td>
                      <td>30%</td>
                      <td>70%</td>
                    </tr>
                    <tr>
                      <td>$25 to $50</td>
                      <td>25%</td>
                      <td>75%</td>
                    </tr>
                    <tr>
                      <td>$50 to $75</td>
                      <td>20%</td>
                      <td>80%</td>
                    </tr>
                    <tr>
                      <td>$75 to $100</td>
                      <td>15%</td>
                      <td>85%</td>
                    </tr>
                    <tr>
                      <td>$100 + </td>
                      <td>10%</td>
                      <td>90%</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className={css.subContainterWrapper}>
                <h2 className={css.redFontClass}>Travel Agent Service</h2>
                <p>
                  Following completion of a SaaP transaction, the customer has the option to
                  continue on and book some or all itinerary items directly with the Trip Expert in
                  their capacity as a Travel Agent. The conversation simply continues via messaging
                  within the Lyfshort Interactive Itinerary App leading up to and during the trip.
                </p>
                <p>
                  Lyfshort, as an independent marketplace facilitator, does not charge commissions,
                  share revenue or margins on travel bookings (Eg. flights, accommodation,
                  activities etc.), however, does charge a nominal lead fee directly associated with
                  the booking opportunities created with every SaaP transaction.
                </p>
                <div className={css.oneRowSection}>
                  <p>Amount the Travel Agency is charged on each SaaP transaction:</p>
                  <table>
                    <tr>
                      <th>Lead Fee</th>
                      <th>USD $5</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className={css.disclaimerSection}>
                <h3>Disclaimer:</h3>
                <ol>
                  <li>
                    Lyfshort is in no way involved in Travel Agent transactions that may occur
                    during the Travel Agent Service, outside of providing a secure and efficient
                    communication medium and a marketplace to facilitate initial introductions
                    created when the Trip Expert SaaP is used. Lyfshort take no responsibility,
                    warrant, support or acknowledge any liability for Travel Agent offers, bookings,
                    transactions or services that may occur while using it’s Organiser App or the
                    Assisted Trip Planning Marketplace. It is the customers sole responsibility to
                    work out whether the pricing, terms and conditions Partner Travel Businesses and
                    associated Trip Experts may offer directly are suitable.
                  </li>
                  <li>
                    Lyfshort reserves the right to update these Commercial Model terms without
                    notice.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}
