import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { PlainTabs } from '../../../components';
import SubTabSectionDetailsContent from './SubTabSectionDetailsContent';
import ListingBookingSection from './ListingBookingSection';
import { PACKAGE_TYPES } from '../../../util/constants';
import { convertMoneyTypeToNumber } from 'util/currency';

const ListingDetailsSectionSubTabs = props => {
  const { authorDetails = {}, featureImage, ...rest } = props;
  const { currentListing } = rest;
  authorDetails.profileImage = featureImage;
  const packages = currentListing.attributes.publicData.packages;
  const basicPrice = packages[0].price;
  const detailedPrice = packages[1].price;
  const basicPriceNumber = convertMoneyTypeToNumber(basicPrice);
  const detailedPriceNumber = convertMoneyTypeToNumber(detailedPrice);

  return (
    <div className={css.subDetailsStickyContainer}>
      <PlainTabs
        menuListClass={css.subDetailsTabMenu}
        data={[
          {
            id: 'basic',
            label: 'Basic',
            price: `${basicPrice.currency}$${basicPriceNumber}`,
            content: <SubTabSectionDetailsContent avatarDetails={authorDetails} tabName={PACKAGE_TYPES.BASIC} tabIndex={0} {...rest} />,
          },
          {
            id: 'detailed',
            label: 'Detailed',
            price: `${detailedPrice.currency}$${detailedPriceNumber}`,
            content: <SubTabSectionDetailsContent avatarDetails={authorDetails} tabName={PACKAGE_TYPES.DETAILED} tabIndex={1} {...rest} />,
          },
          {
            id: 'custom',
            label: 'Custom',
            price: '',
            content: <ListingBookingSection avatarDetails={authorDetails} tabName={PACKAGE_TYPES.CUSTOM} tabIndex={2} {...rest} />,
          },
        ]}
      />
    </div>
  );
};
export default ListingDetailsSectionSubTabs;
