import React from 'react';
import {
  AboutPage,
  OverviewPage,
  TripExpertGettingStartedPage,
  BusinessGettingStartedPage,
  OrderDeliveryGuidePage,
  GlobalAvailabilityPage,
  AuthenticationPage,
  CheckoutPage,
  CheckoutPageWithoutPayment,
  EditListingPage,
  EmailVerificationPage,
  InboxPage,
  LandingPage,
  ListingPage,
  NotFoundPage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  ProfilePage,
  ProfileSettingsPage,
  SearchPage,
  BecomeTripExpertPage,
  TermsOfServicePage,
  TransactionPage,
  ProfileAvatarPage,
  TravelAgentTnCPage,
  CommercialModelPage,
  ProcessesPage,
  DefinitionsPage,
  JoinPartnerPage,
  BecomeExpertOrPartnerPage,
  AssociatedAccountsPage,
  UsersDashboardPage,
  FavListingsPage,
  PartnerOrdersPage,
  PartnerMemberSalesPage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { LISTING_TYPES } from 'util/constants';

export const ACCOUNT_SETTINGS_PAGES = [
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
      loadData: LandingPage.loadData,
    },
    {
      path: '/overview',
      name: 'OverviewPage',
      component: OverviewPage,
    },
    {
      path: '/partner-global-availability',
      name: 'GlobalAvailabilityPage',
      component: GlobalAvailabilityPage,
    },
    {
      path: '/commercial-model',
      name: 'CommercialModelPage',
      component: CommercialModelPage,
    },
    {
      path: '/processes',
      name: 'ProcessesPage',
      component: ProcessesPage,
    },
    {
      path: '/definitions',
      name: 'DefinitionsPage',
      component: DefinitionsPage,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/partner-orders-page',
      name: 'PartnerOrdersPage',
      component: PartnerOrdersPage,
    },
    {
      path: '/admin-partners',
      name: 'UsersDashboardPage',
      component: UsersDashboardPage,
    },
    {
      path: '/join-partner',
      name: 'JoinPartnerPage',
      auth: 'true',
      authPage: 'LoginPage',
      component: JoinPartnerPage,
    },
    {
      path: '/become-expert-or-partner',
      name: 'BecomeExpertOrPartnerPage',
      component: BecomeExpertOrPartnerPage,
    },
    {
      path: '/associated-accounts',
      name: 'AssociatedAccountsPage',
      auth: 'true',
      component: AssociatedAccountsPage,
    },
    {
      path: '/favListings',
      name: 'FavListingsPage',
      component: FavListingsPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    // {
    //   path: '/s/filters',
    //   name: 'SearchFiltersPage',
    //   component: props => <SearchPage {...props} tab="filters" />,
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:credits/:paymentType?/checkout_c',
      name: 'CheckoutPageWithoutPayment',
      auth: true,
      component: props => <CheckoutPageWithoutPayment {...props} />,
      setInitialValues: CheckoutPageWithoutPayment.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'overview' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} allowOnlyOneListing={false} />,
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.LISTING }),
    },
    // Company Create/Edit page
    {
      path: '/c/new',
      name: 'NewCompanyPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditCompanyPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'introduction' }}
        />
      ),
    },
    {
      path: '/c/:slug/:id/:type/:tab',
      name: 'EditCompanyPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.COMPANY}
          {...props}
          allowOnlyOneListing={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.COMPANY }),
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales', state: 'all' }} />,
    },
    {
      path: '/inbox/:tab/:state/:action?',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/partner/member/:id/sales',
      name: 'PartnerMemberSalesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PartnerMemberSalesPage {...props} />,
      loadData: PartnerMemberSalesPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PasswordChangePage {...props} />,
    // },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/travel-agent-t&c',
      name: 'TravelAgentTnCPage',
      component: props => <TravelAgentTnCPage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
    {
      path: '/trip_expert',
      name: 'BecomeTripExpertPage',
      component: props => <BecomeTripExpertPage {...props} />,
      loadData: BecomeTripExpertPage.loadData,
    },
    {
      path: '/profile-avatar',
      name: 'ProfileAvatarPage',
      auth: true,
      component: props => <ProfileAvatarPage {...props} />,
    },
    {
      path: '/help/trip-expert-getting-started',
      name: 'TripExpertGettingStartedPage',
      component: props => <TripExpertGettingStartedPage {...props} />,
    },
    {
      path: '/help/business-getting-started',
      name: 'BusinessGettingStartedPage',
      component: props => <BusinessGettingStartedPage {...props} />,
    },
    {
      path: '/help/order-delivery',
      name: 'OrderDeliveryGuidePage',
      component: props => <OrderDeliveryGuidePage {...props} />,
    },
  ];
};

export default routeConfiguration;
