import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import { CustomBookingForm } from '../../../forms'

const ListingBookingSection = props => {
  const {
    currentListing,
    currentUser,
    isOwnListing,
    unitType,
    handlePackagePurchase,
    bookingTitle,
    authorDisplayName,
    intl,
    tabName,
    currencyConversionRate,
  } = props;

  return (
    <div className={css.listingBookingSection}>
      <div className={css.bookingPanelContainer}>
        <CustomBookingForm
          className={css.bookingPanel}
          listing={currentListing}
          currentUser={currentUser}
          isOwnListing={isOwnListing}
          unitType={unitType}
          onSubmit={handlePackagePurchase}
          title={bookingTitle}
          authorDisplayName={authorDisplayName}
          intl={intl}
          tabName={tabName}
          currencyConversionRate={currencyConversionRate}
        />
        <h3>WHAT YOU GET</h3>
        <p>
          The Trip Expert request will gather general ideas such as where, when, who with, budget,
          preferences and what you would like included. I will then research and craft a
          personalised itinerary, delivered online through the Lyfshort App within the quoted
          delivery time. Following delivery you have collaboration time to request adjustments and
          modifications using messaging. The service will finish when you aknowledge completion or
          the collaboration window expires.
        </p>
        <p>
          <span className={css.boldBlackText}>Bookings are not included in this price</span>, only research and itinerary
          construction, but after completion, you will have the option to book and pay for some or
          all of the items with me as a <span className={css.boldBlackText}>certified Travel Agent,</span> continuing to
          communicate together through the App.
        </p>
      </div>
    </div>
  );
};

export default ListingBookingSection;
