import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import cns from 'classnames';
import { memoize } from 'lodash/function';
import { propTypes } from 'util/types';
import config from 'config';
import { arrayOf, bool, number, object, shape } from 'prop-types';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import css from 'components/IncomeContent/IncomeContent.css';
import RightLightArrow from '../../assets/Landing_Pages/RightLightArrow.svg';

import {
  addMissingDates,
  dataByUniqDate,
  getCompletedTransitionDate,
  getPeriodDates,
  getPeriodTotalGross,
  intoChartData,
  sortByDate,
} from 'components/IncomeContent/helpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from 'util/currency';

const { Money } = sdkTypes;

export const PERIODS = {
  WEEK: {
    key: 'week',
    label: '1 WEEK',
    labelId: 'IncomePage.weekLabel',
    mobileLabelId: 'IncomePage.weekLabelMobile',
  },
  MONTH: {
    key: 'month',
    label: '1 MONTH',
    labelId: 'IncomePage.monthLabel',
    mobileLabelId: 'IncomePage.monthLabelMobile',
  },
  YEAR: {
    key: 'year',
    label: '1 YEAR',
    labelId: 'IncomePage.yearLabel',
    mobileLabelId: 'IncomePage.yearLabelMobile',
  },
  ALLTIME: {
    key: 'ALLTIME',
    label: 'ALL TIME',
    labelId: 'IncomePage.allTimeLabel',
    mobileLabelId: 'IncomePage.allTimeLabelMobile',
  },
};

export const getTodayData = memoize((transactions, currentDateObject) => {
  const todayTxs = transactions.filter(tx =>
    currentDateObject.clone().isSame(getCompletedTransitionDate(tx), 'day')
  );
  const todayGrossNumber = todayTxs.reduce(
    (acc, tx) => acc + tx.attributes.metadata.amountPaid || tx.attributes.payoutTotal.amount,
    0
  );
  const todayGross = new Money(todayGrossNumber, config.currency);

  return { todayGross, paymentsNumber: todayTxs.length };
});

const getData = (transactions, period, currentDateObject, currentUser, step = 0) => {
  const { periodStart, periodEnd } = getPeriodDates(
    currentUser.attributes.createdAt,
    period.key,
    currentDateObject,
    step,
  );
  const dates = { periodStart, periodEnd };

  const filteredTransactions = transactions.filter(tx => {
    const txCompletedDate = getCompletedTransitionDate(tx);
    return moment(txCompletedDate).isAfter(periodStart, 'day') && moment(txCompletedDate).isSameOrBefore(periodEnd, 'day');
  });

  const initChartData = filteredTransactions
    .sort(sortByDate)
    .reduce((acc, tx) => dataByUniqDate(period, acc, tx), {});

  const periodTotalGross = getPeriodTotalGross(initChartData);

  const dataWithMissingDates = addMissingDates(
    initChartData,
    period,
    periodStart,
    currentDateObject
  );

  const [labels, data] = dataWithMissingDates.reduce(intoChartData, [[], []]);

  return {
    dates: dates,
    periodTotalGross,
    chartData: {
      labels: labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 5,
          pointHoverRadius: 2,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 5,
          pointRadius: 2,
          pointHitRadius: 5,
          spanGaps: true,
          data: data,
        },
      ],
    },
  };
};

const MAX_MOBILE_SCREEN_WIDTH = 550;

function IncomeContent(props) {
  const [period, setPeriod] = useState(PERIODS.ALLTIME);
  const [todayInfo, setTodayInfo] = useState({});
  const [chartData, setChartData] = useState({});
  const [periodTotal, setPeriodTotal] = useState(new Money(0, config.currency));
  const [periodDates, setPeriodDates] = useState({});
  const [step, setStep] = useState(0);

  const currentDateObject = moment();
  const { intl, transactions, currentUser, viewport } = props;

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  useEffect(() => {
    const todayData = getTodayData(transactions, currentDateObject);
    setTodayInfo(todayData);

    const { chartData, dates, periodTotalGross } = getData(
      transactions,
      period,
      currentDateObject,
      currentUser
    );

    setChartData(chartData);
    setPeriodTotal(periodTotalGross);
    setPeriodDates(dates);
    /* eslint-disable-next-line */
  }, [transactions, currentUser, period]);

  useEffect(() => {
    const { chartData, dates, periodTotalGross } = getData(
      transactions,
      period,
      currentDateObject,
      currentUser,
      step,
      );
    setChartData(chartData);
    setPeriodTotal(periodTotalGross);
    setPeriodDates(dates);
  }, [step]);

  const onScrollArrowClickLeft = e => {
    setStep(step - 1);
  };

  const onScrollArrowClickRight = e => {
    setStep(step + 1);
  };

  const { periodStart, periodEnd } = periodDates;

  return (
    <div>
      <div className={css.header}>
        <div className={css.headerTitle}>
          <span className={css.headerTitleText}>
            <FormattedMessage
              id={'IncomePage.todayGross'}
              values={{
                date: (
                  <span className={css.headerDate}>
                    {currentDateObject.clone().format('D MMM')}
                  </span>
                ),
              }}
            />
          </span>
        </div>

        <div className={css.headerMain}>
          {todayInfo.todayGross && (
            <span className={css.gross}>{formatMoney(intl, todayInfo.todayGross)}</span>
          )}

          <span className={css.paymentNum}>
            <FormattedMessage
              id={'IncomePage.todayPayments'}
              values={{ payments: todayInfo.paymentsNumber }}
            />
          </span>
        </div>
      </div>

      <div className={css.chartSection}>
        <ul className={css.chartMenu}>
          {Object.entries(PERIODS).map(([key, item]) => (
            <li
              key={item.key}
              onClick={() => setPeriod(item)}
              className={cns(css.chatMenuItem, period.key === item.key && css.chatMenuItemActive)}
            >
              <FormattedMessage id={isMobileLayout ? item.mobileLabelId : item.labelId} />
            </li>
          ))}
        </ul>

        <div className={css.periodGross}>
          <div className={css.grossWrap}>
            <div className={css.grossHeader}>Gross Revenue</div>
            <span className={css.gross}>{formatMoney(intl, periodTotal)}</span>
          </div>

          <div className={css.periodDateWrap}>
            {periodStart && periodEnd && (
              <>
                {period.key !== PERIODS.ALLTIME.key && (
                <div className={cns(css.img, css.left)} onClick={onScrollArrowClickLeft}>
                  <img src={RightLightArrow} alt='arrow' />
                </div>)}
                <div className={css.date}>{`${moment(periodStart).format('DD MMM, YYYY')} - ${moment(periodEnd).format('DD MMM, YYYY')}`}</div>
                {period.key !== PERIODS.ALLTIME.key && (
                <div className={cns(css.img, css.right)} onClick={onScrollArrowClickRight}>
                  <img src={RightLightArrow} alt='arrow' />
                </div>)}
              </>
            )}
          </div>
        </div>

        <div className={css.chartWrap}>
          <Line
            data={chartData}
            width={676}
            height={338}
            options={{
              responsive: true,
              animation: {
                duration: 0,
              },
              hover: {
                animationDuration: 0,
              },
              responsiveAnimationDuration: 3000,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxRotation: 12,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

IncomeContent.propTypes = {
  transactions: arrayOf(propTypes.transaction),
  currentUser: object,
  isLoading: bool,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }),
  intl: intlShape,
};

export default IncomeContent;
