import React, { Component } from 'react';
import classNames from 'classnames';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  NamedLink,
  Footer,
} from 'components';
import { FormattedMessage } from 'react-intl';
import { StaticPage, TopbarContainer } from 'containers/index';
import css from './OverviewPage.css';
import Collapsible from 'react-collapsible';

import processDiagram from '../../assets/TripExpertPage/Assited_Trip_Planning_Graphic.svg';
import diagram1 from '../../assets/Overview_Page/Hire_Trip_Expert_Process_Diagram.svg';
import diagram2 from '../../assets/Overview_Page/Become_a_Trip_Expert_Process_Diagram.svg';
import diagram3 from '../../assets/Overview_Page/Partner_Process_Diagram.svg';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const yOffset = -100;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }
}

const userTypeList = [
  {
    id: "traveler",
    label: "Traveler (Customer)",
    summary: [
      "Save time, money and improve trip planning quality, hire a Trip Expert.  Conducting research, building a plan from high level requirements, then sharing as an Interactive Itinerary within a purpose built App available on your device, collaborating with direct messaging.",
      "Customers initially browse Trip Experts from all around the world with varying array of skills, location experience and specializations then hire them by purchasing set price “Service as a Product” (SaaP) research and itinerary construction packages. Once itinerary delivery is complete customers can also choose to book some or all of the items directly with the Trip Expert in their capacity as a Travel Agent, or book with suppliers themselves.",
    ],
    details: [
      "Browse Trip Expert profiles and select one you like based on criteria such as location or trip specialization, price, interests, experience along with a <b>\"Service as a Product\" (SaaP)</b> hire package.",
      "Create a Lyfshort profile.  (Required to place an order on the Marketplace).",
      "Start an order request including completing the information details that will be delivered to the Trip Expert with your order to base research on Eg. Estimate Budget, Dates, Transport, Participants, Accommodation Type, Activities and anything else that will make the trip awesome.",
      "Send the order request with Credit Card details and await Trip Expert Acceptance.",
      "Ensure you have downloaded the free Lyfshort Trip Organiser App from the App or Play stores to receive your interactive itinerary, check delivery status and communicate with your Trip Expert. (Must log in to Lyfshort Organiser App with the same userID as the order was placed on the Marketplace.  To check compare profile email addresses in the Lyfshort App and on the marketplace when logged in).",
      "Upon Trip Expert order acceptance, research is conducted and an Interactive Itinerary is created and shared via the Lyfshort App within the specified time frame detailed in the SaaP purchased.",
      "Using messaging in the Lyfshort App, changes to the itinerary can then be requested by the customer and made by the Expert within the second collaboration time window detailed in the SaaP.",
      "The customer then either accepts the order as complete or the collaboration time window expires which also completes the order.",
      "Following completion the customer now has the option to book some or all of the itinerary items directly with the Trip Expert in their capacity as a Travel Agent (or book directly with suppliers themselves).",
      "For booked items, the conversation simply continues via messaging within the interactive itinerary leading up to and during the trip.",
      "If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery and completion of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.",
    ],
  },
  {
    id: "expert",
    label: "Travel Agent (Trip Expert)",
    summary: [
      "Are you a Travel Agent that would like to receive qualified booking leads while also getting paid directly for the value you deliver when creating Itineraries?  Would you like to set your own \"Service as a Product\" (SaaP) pricing, access new global markets and simplify communication with your customers?",
      "Lyfshort's Assisted Trip Planning marketplace enables Travelers to hire Trip Experts by purchasing set price SaaP packages, then collaboratively planning trips together.  Setup a profile and sales page quickly, focus on specialized trips or locations you love, continue to use existing internal IT and booking systems, access a purpose built Interactive Itinerary App to communicate (Instead of a spaghetti of emails, phone calls and SMS's etc).",
      "No up front or monthly costs, no commissions or revenue splits on Travel bookings.  SaaP hire charges are allocated 70% - 90% to the Trip Expert, minus a small Travel Agency lead fee, with the remainding % contributing to marketplace operation. <a>More details here</a>. Any direct customer booking revenue related to the trip (Flights, Accommodation, Activities etc.) is 100% retained.  Travel Agencies can apply for marketplace partnership, and, on approval, invite their Travel Agents to set up Trip Expert profiles, SaaP pricing packages and then start taking order requests from new customers from around the world."
    ],
    details: [
      "To become a Trip Expert and offer services on the marketplace you must be both a registered Travel Agent and work for or be affiliated with a Partner Travel Agency.",
      "Lyfshort is looking for Partner Travel Agencies so suggest contacting the owner or administrator of your Travel Agency to complete a Partnership Request, and get approved.",
      "Once Approved, Partner Travel Business administrators send invites to their Travel Agents via email or a unique link from the marketplace.",
      "The email/link will guide you to first create a Lyfshort profile.  (If you already have a profile, log in to the Marketplace).",
      "Navigate to the top right hand Avatar menu and you should now see a second \"Trip Expert\" section in the drop down menu, the sub-menu options under this heading are where you manage customers and work on the marketplace.",
      "Sections include \"Sales Listing Page\", \"Order Management\",\"Reporting\" and \"Messages\".",
      "Start by completing all sections in the sales listing page. They include \"Overview\", \"Locations\", \"Gallery\", \"Pricing\"",
      "A draft view is available before publishing live to the Marketplace.  Once you have \"Published\", your Trip Expert sales page should be visible in marketplace searches.  (Suggest testing).",
      "Most sections are self explanatory.  \"Pricing\" is where the SaaP package inclusions are configured and customer expectations are set, so spend some time getting this right.  All prices are quoted in USD.",
      " \"Basic\", \"Detailed\" and \"Custom\" packages need to be configured.  Each has 4 variables, <b>Maximum number of itinerary items</b> and <b>participants</b> included in the research along with <b>delivery</b> and <b>collaboration time windows</b>.",
      "<b>\"Basic\"</b> should represent the minimum sale amount and configuration you would be willing to accept an order and create a client relationship for.",
      "<b>\"Detailed\"</b> would be the ideal configuration you think will cover most of your style of customers requirements at a reasonable price.",
      "<b>\"Custom\"</b> is different to the first 2 packages and based on setting individual unit prices for each SaaP variable.  This is very flexible for customers to self configure, however, check the custom pricing configuration is not cheaper than the same packaged variables in Basic and Detailed pricing configurations as they are meant to represent good value.",
      "Standard discounts or premiums can also be setup for faster or slower delivery and collaboration times.  This can help with managing concurrent workload.",
      "After completing all the sections the Sales Page Listing can now be \"Published\" from the Draft View screen.",
      "Once published, customers can now submit order requests for your SaaP packages on the marketplace, email notifications will be sent when orders are received.",
      "Orders will contain a package of completed information from the customer with details to start researching and itinerary construction within the Lyfshort App, including dates, locations, budgets, transport and accommodation requirements and any other details the customer wants considered.",
      "To start delivery, Trip Experts must visit the \"Order Management\" menu (when logged into the marketplace) and \"Accept\" the order.  This will also start the delivery time window committed to in the SaaP.",
      `It is now time to start creating the interactive itinerary in the Lyfshort App.  First create a Trip then add relevant itinerary items as you are researching details relating to the requirements.`,
      `At this stage nothing has been shared with the customer however they know work has started on the research and itinerary as the order was accepted and it's within the delivery time window detailed in the SaaP.`,
      `Once the itinerary is ready to "Deliver" open the top Trip itinerary item in the Lyfshort App then press the "Group" section and invite the customer to join using their Lyfshort username.  (This can be located on the SaaP order details).`,
      `The customer will get a notification that their itinerary has been delivered, the delivery time window is now complete and the collaboration time window starts.  Ensure delivery is within the specified SaaP window the customer has purchased.  This can impact both the likelihood of the customer booking Travel Agent services and a good review after the SaaP is completed.`,
      `The customer now has the collaboration time window to provide feedback and request any changes to the Trip Expert using direct messaging within the itinerary.`,
      `It is important to be responsive on messaging during the collaboration window, this is part of  the customer's SaaP purchase.  It is also a great opportunity as a Trip Expert to increase the probability of the customer expanding the service to include Travel Agent bookings by offering great service.`,
      `When the customer is happy with the final itinerary they mark it as complete in Lyfshort.  Alternatively the collaboration time window expires and it is automatically completed.`,
      `The customer is then asked if they would like to book itinerary items through the Trip Expert in their capacity as a Travel Agent or not.  If they do, the messaging conversation continues within the shared itinerary and booking and payment details are sorted out directly between the 2 parties.`,
      `If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery and completion of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.`,
    ],
  },
  {
    id: "agency",
    label: "Partner Travel Business (Travel Agency)",
    summary: [
      "Becoming a Lyfshort Marketplace Business Partner provides existing Travel Agencies the opportunity of new customers and revenue in wide Geographical markets and is quickly scalable across teams of Travel Agents.",
      "Once approved, a Travel Agency administrator can create and send invite links to Travel Agents associated with their business, who in turn create individual \"Trip Expert\" multimedia sales listing pages and \"Service as a Product\" (SaaP) packages for purchase by customers on the marketplace.",
      "Following purchase the \"Trip Expert\" conducts personalized research and creates an Interactive Itinerary via the Lyfshort Trip Organiser App within an agreed time frame in a “Service as a Product” (SaaP) package.  Changes can be requested within a second \"collaboration\" time window using direct messaging (removing the need for a spaghetti of emails, phone calls, SMS's etc.), then the SaaP is complete.   The customer now also has the option to book itinerary items directly with the Trip Expert in their capacity as a Travel Agent (or book directly with suppliers themselves).  For booked items, the conversation simply continues via messaging within the Lyfshort Interactive itinerary App leading up to and during the trip.  The Travel Agency administrator has central access to all linked Trip Expert transactions, reporting and sales proceeds.  It is the Travel Agency Administrators responsibility to transfer SaaP order funds to the individual Travel Agents."
    ],
    details: [
      `A Travel Agency owner or Manager applies to become a "Partner Travel Business" on the Lyfshort Marketplace.`,
      `Travel Business Partnerships are only available in countries supported by Stripe Connect payments (at this stage).  They included Australia, Austria, Belgium, Brazil, Bulgaria, Canada, Cyprus, the Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, India, Ireland, Italy, Japan, Latvia, Lithuania, Luxembourg, Malta, Mexico, the Netherlands, New Zealand, Norway, Poland, Portugal, Romania, Singapore, Slovakia, Slovenia, Spain, Sweden, Switzerland, Thailand, the United Kingdom, and the United States.  Additional countries are being evaluated, for unsupported countries please contact Lyfshort support.`,
      `Trip Expert (Travel Agent) sales listings linked to Partner Travel Businesses offering SaaP packages can be located anywhere.`,
      `Application information required includes Business, Financial and administrative details along with brand imagery for a <business>Business Listing Page</business>.`,
      `The application goes through a financial and commercial diligence process, including Stripe Bank Account registration, and is manually reviewed by Lyfshort.`,
      `Upon approval, the Travel Business Listing page is published and additional administration menu access is enabled (Available in the top right Avatar Menu drop down).`,
      `The Travel Agency Business Admin can now invite Travel Agent staff or associates (Via email or unique link) to create a Lyfshort profile and Trip Expert listing page, linked to their Partner Business listing page.`,
      `Each Trip Expert sets their own pricing and packaged "Service as a Product (SaaP)" inclusions via the "Trip Expert" menu section, (available in the top right corner after a business invite is accepted form the Travel Agency administrator).`,
      `There are 4 SaaP package inclusion variables set and controlled by the Trip Expert :
      Maximum 1. Number of itinerary Items 2. Number of participants 3. Delivery time window 4. Collaboration time window.
      `,
      `All sales for Trip Expert SaaP's on the Marketplace will be deposited in the registered bank account of the Partner Travel Business (Via Stripe).`,
      `It is the Partner Travel Businesses responsibility to manage distribution and payment of proceeds to Travel Agents for their SaaP work .  Relevant supporting transaction reporting is available.`,
      `The Partner Travel Business will receive the following payment on successful SaaP delivery :
      (USD Total SaaP Customer Charge) - (USD SaaP *Marketplace % fee + USD **lead fee) = (Travel Business Retained Payment amount) paid out in linked account currency, based on Stripe’s standard terms and conditions for the region.

      *Marketplace % fee on SaaP transactions includes but is not limited to :
      Prospect marketing, Travel Business and Trip Expert sales listing, order process management and organizer App integration, transaction reporting, credit card processing fees, currency conversion fees and support.

      **Lead fee covers : The introduction to a qualified opportunity to directly sell Travel Agent products and services to (Eg. Flights, accommodation, car hire, activities etc.) following a SaaP completion. <c>More details here</c>.`,
      `Following completion of the SaaP research and itinerary service, the customer is given the option to book some or all of the itinerary items with the Trip Expert in their capacity as a Travel Agent, or do it themselves.`,
      `If the customer chooses to use the Trip Expert as their Travel Agent, communication continues directly through the Interactive Itinerary and messaging.`,
      `The Travel Agency / Travel Agents continue using the same internal booking systems,  processes, and IT systems however, instead of communicating via emails, phone calls, SMS's etc. with customers, Interactive Itineraries and messaging are now centrally used with customers.`,
      `Lyfshort does not charge any commission % or revenue splits related to travel bookings, only the small transactional lead fee associated with the marketplace connection made.`,
      `If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery and completion of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.`,
    ]
  },
]

const selectDiagram = (i) => {
  switch (i) {
    case 0:
      return diagram1;
    case 1:
      return diagram2;
    case 2:
      return diagram3;
    default:
      return
  }
}
export default class OverviewPage extends Component {

  render() {
    return (
      <StaticPage title="Overview Page">
        <LayoutSingleColumn>
          <LayoutWrapperTopbar >
            <TopbarContainer isDarkMode={true} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain >
            <div className={css.overviewSection}>
              <div className={css.containerWrapper}>
                <h2>Assisted Trip Planning Marketplace</h2>
                <h2 className={css.redFontClass}>Overview</h2>
                <div className={css.processDiagramWrapper}>
                  <img src={processDiagram} alt="process Diagram" width="100%" />
                </div>
                <h3>User Types</h3>
                {userTypeList.map((type, index) => (
                  <div className={css.bookmarkLink}>
                    <a key={index} onClick={() => scrollToSection(type.id)}>{index + 1}. {type.label}</a>
                  </div>
                ))}

                {/* main content */}
                {userTypeList.map((type, index) => (
                  <div className={css.sectionContainer}>
                    <h3 id={type.id} className={classNames(css.bookmarkLink, css.redFontClass)}>
                      {index + 1}. {type.label}
                    </h3>
                    <h3>Service Summary</h3>
                    {type.summary.map((summary, index) => (
                      <p key={index}>
                        <FormattedMessage
                          id={"summary" + index}
                          defaultMessage={summary}
                          values={{
                            a: chunks => <NamedLink name="CommercialModelPage" target='_blank'>
                              {chunks}
                            </NamedLink>,
                          }}
                        />
                      </p>
                    ))}
                    <Collapsible
                      trigger={<div className={css.expandableBar}>
                        <span>Getting Started &gt;&gt;</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.expandableBar}>
                        <span>Getting Started &gt;&gt;</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>
                      }
                    >
                      <div className={css.sectionDiagramWrapper}>
                        <img src={selectDiagram(index)} alt={"diagram" + index} />
                      </div>
                      <ol style={{ listStyleType: "disc" }}>
                        {type.details.map((sentence, index) => (
                          <li key={index}>
                            <p>
                              <FormattedMessage
                                id={"sentence" + index}
                                defaultMessage={sentence}
                                values={{
                                  business: chunks => <NamedLink name="SearchPage" to={{ search: `pub_listing_type=company` }}>
                                    {chunks}
                                  </NamedLink>,
                                  a: chunks => <NamedLink name="TravelAgentTnCPage">
                                    {chunks}
                                  </NamedLink>,
                                  b: (...chunks) => <b>{chunks}</b>,
                                  c: chunks => <NamedLink name="CommercialModelPage" target="_blank">
                                    {chunks}
                                  </NamedLink>
                                }}
                              />
                            </p>
                          </li>
                        ))}
                      </ol>
                    </Collapsible>
                  </div>
                ))}
              </div>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    )
  };
}
