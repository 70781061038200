// This is a copy of currencyDB used in the organiser app code

export const currencyDB = [
  {
    id: 1,
    label: 'USD',
    value: 'USD',
    sign: '$',
    country: 'United States of America',
    flag: '🇺🇸',
    code: 'us',
  },
  {
    id: 2,
    label: 'EUR',
    value: 'EUR',
    sign: '€',
    country: 'European Union',
    flag: '🇪🇺',
    code: 'eu',
  },
  { id: 3, label: 'JPY', value: 'JPY', sign: '¥', country: 'Japan', flag: '🇯🇵', code: 'jp' },
  {
    id: 4,
    label: 'GBP',
    value: 'GBP',
    sign: '£',
    country: 'United Kingdom',
    flag: '🇬🇧',
    code: 'gb',
  },
  { id: 5, label: 'AUD', value: 'AUD', sign: '$', country: 'Australia', flag: '🇦🇺', code: 'au' },
  { id: 6, label: 'CAD', value: 'CAD', sign: '$', country: 'Canada', flag: '🇨🇦', code: 'ca' },
  { id: 7, label: 'CHF', value: 'CHF', sign: '₣', country: 'Switzerland', flag: '🇨🇭', code: 'ch' },
  { id: 8, label: 'CNY', value: 'CNY', sign: '¥', country: 'China', flag: '🇨🇳', code: 'cn' },
  {
    id: 9,
    label: 'HKD',
    value: 'HKD',
    sign: '$',
    country: 'Hong Kong SAR China',
    flag: '🇭🇰',
    code: 'hk',
  },
  { id: 10, label: 'NZD', value: 'NZD', sign: '$', country: 'New Zealand', flag: '🇳🇿', code: 'nz' },
  {
    id: 11,
    label: 'AED',
    value: 'AED',
    sign: '$',
    country: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'ae',
  },
  { id: 12, label: 'AFN', value: 'AFN', sign: '؋', country: 'Afghanistan', flag: '🇦🇫', code: 'af' },
  { id: 13, label: 'ALL', value: 'ALL', sign: 'L', country: 'Albania', flag: '🇦🇱', code: 'al' },
  { id: 14, label: 'AMD', value: 'AMD', sign: '֏', country: 'Armenia', flag: '🇦🇲', code: 'am' },
  {
    id: 15,
    label: 'ANG',
    value: 'ANG',
    sign: 'ƒ',
    country: 'Netherlands Antilles',
    flag: '🇳🇱',
    code: 'nl',
  },
  { id: 16, label: 'AOA', value: 'AOA', sign: '$', country: 'Angola', flag: '🇦🇴', code: 'ao' },
  { id: 17, label: 'ARS', value: 'ARS', sign: '$', country: 'Argentina', flag: '🇦🇷', code: 'ar' },
  { id: 18, label: 'AWG', value: 'AWG', sign: 'ƒ', country: 'Aruba', flag: '🇦🇼', code: 'aw' },
  { id: 19, label: 'AZN', value: 'AZN', sign: '₼', country: 'Azerbaijan', flag: '🇦🇿', code: 'az' },
  {
    id: 20,
    label: 'BAM',
    value: 'BAM',
    sign: 'KM ',
    country: 'Bosnia & Herzegovina',
    flag: '🇧🇦',
    code: 'ba',
  },
  { id: 21, label: 'BBD', value: 'BBD', sign: '$', country: 'Barbados', flag: '🇧🇧', code: 'bb' },
  { id: 22, label: 'BDT', value: 'BDT', sign: '৳', country: 'Bangladesh', flag: '🇧🇩', code: 'bd' },
  { id: 23, label: 'BGN', value: 'BGN', sign: 'лв ', country: 'Bulgaria', flag: '🇧🇬', code: 'bg' },
  { id: 24, label: 'BHD', value: 'BHD', sign: '.د.ب ', country: 'Bahrain', flag: '🇧🇭', code: 'bh' },
  { id: 25, label: 'BIF', value: 'BIF', sign: 'FBu ', country: 'Burundi', flag: '🇧🇮', code: 'bi' },
  { id: 26, label: 'BMD', value: 'BMD', sign: '$', country: 'Bermuda', flag: '🇧🇲', code: 'bm' },
  { id: 27, label: 'BND', value: 'BND', sign: '$', country: 'Brunei', flag: '🇧🇳', code: 'bn' },
  { id: 28, label: 'BOB', value: 'BOB', sign: '$b ', country: 'Bolivia', flag: '🇧🇴', code: 'bo' },
  { id: 29, label: 'BRL', value: 'BRL', sign: 'R$ ', country: 'Brazil', flag: '🇧🇷', code: 'br' },
  { id: 30, label: 'BSD', value: 'BSD', sign: '$', country: 'Bahamas', flag: '🇧🇸', code: 'bs' },
  { id: 32, label: 'BTN', value: 'BTN', sign: 'Nu ', country: 'Bhutan', flag: '🇧🇹', code: 'bt' },
  { id: 33, label: 'BWP', value: 'BWP', sign: 'P', country: 'Botswana', flag: '🇧🇼', code: 'bw' },
  { id: 34, label: 'BYN', value: 'BYN', sign: 'Br ', country: 'Belarus', flag: '🇧🇾', code: 'by' },
  { id: 35, label: 'BZD', value: 'BZD', sign: 'BZ$ ', country: 'Belize', flag: '🇧🇿', code: 'bz' },
  {
    id: 37,
    label: 'CDF',
    value: 'CDF',
    sign: '$',
    country: 'Congo - Kinshasa',
    flag: '🇨🇩',
    code: 'cd',
  },
  { id: 39, label: 'CLF', value: 'CLF', sign: 'UF', country: 'Chile', flag: '🇨🇱', code: 'cl' },
  { id: 40, label: 'CLP', value: 'CLP', sign: '$', country: 'Chile', flag: '🇨🇱', code: 'cl' },
  { id: 43, label: 'COP', value: 'COP', sign: '$', country: 'Colombia', flag: '🇨🇴', code: 'co' },
  { id: 44, label: 'CRC', value: 'CRC', sign: '₡', country: 'Costa Rica', flag: '🇨🇷', code: 'cr' },
  { id: 45, label: 'CUC', value: 'CUC', sign: '$', country: 'Cuba', flag: '🇨🇺', code: 'cu' },
  { id: 46, label: 'CUP', value: 'CUP', sign: '₱', country: 'Cuba', flag: '🇨🇺', code: 'cu' },
  { id: 47, label: 'CVE', value: 'CVE', sign: '$', country: 'Cape Verde', flag: '🇨🇻', code: 'cv' },
  { id: 48, label: 'CZK', value: 'CZK', sign: 'Kč ', country: 'Czechia', flag: '🇨🇿', code: 'cz' },
  { id: 49, label: 'DJF', value: 'DJF', sign: '$', country: 'Djibouti', flag: '🇩🇯', code: 'dj' },
  { id: 50, label: 'DKK', value: 'DKK', sign: 'kr ', country: 'Denmark', flag: '🇩🇰', code: 'dk' },
  {
    id: 51,
    label: 'DOP',
    value: 'DOP',
    sign: 'RD$ ',
    country: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'do',
  },
  { id: 52, label: 'DZD', value: 'DZD', sign: '$', country: 'Algeria', flag: '🇩🇿', code: 'dz' },
  { id: 53, label: 'EGP', value: 'EGP', sign: 'E£ ', country: 'Egypt', flag: '🇪🇬', code: 'eg' },
  { id: 54, label: 'ERN', value: 'ERN', sign: 'Nkf ', country: 'Eritrea', flag: '🇪🇷', code: 'er' },
  { id: 55, label: 'ETB', value: 'ETB', sign: 'Br ', country: 'Ethiopia', flag: '🇪🇹', code: 'et' },
  { id: 57, label: 'FJD', value: 'FJD', sign: '$', country: 'Fiji', flag: '🇫🇯', code: 'fj' },
  {
    id: 58,
    label: 'FKP',
    value: 'FKP',
    sign: '£',
    country: 'Falkland Islands',
    flag: '🇫🇰',
    code: 'fk',
  },
  { id: 60, label: 'GEL', value: 'GEL', sign: '₾', country: 'Georgia', flag: '🇬🇪', code: 'ge' },
  { id: 62, label: 'GHS', value: 'GHS', sign: 'GH₵ ', country: 'Ghana', flag: '🇬🇭', code: 'gh' },
  { id: 63, label: 'GIP', value: 'GIP', sign: '£', country: 'Gibraltar', flag: '🇬🇮', code: 'gi' },
  { id: 64, label: 'GMD', value: 'GMD', sign: 'D', country: 'Gambia', flag: '🇬🇲', code: 'gm' },
  { id: 65, label: 'GNF', value: 'GNF', sign: 'Fr  ', country: 'Guinea', flag: '🇬🇳', code: 'gn' },
  { id: 66, label: 'GTQ', value: 'GTQ', sign: 'Q', country: 'Guatemala', flag: '🇬🇹', code: 'gt' },
  { id: 67, label: 'GYD', value: 'GYD', sign: '$', country: 'Guyana', flag: '🇬🇾', code: 'gy' },
  { id: 69, label: 'HNL', value: 'HNL', sign: 'L', country: 'Honduras', flag: '🇭🇳', code: 'hn' },
  { id: 70, label: 'HRK', value: 'HRK', sign: 'kn  ', country: 'Croatia', flag: '🇭🇷', code: 'hr' },
  { id: 71, label: 'HTG', value: 'HTG', sign: '$', country: 'Haiti', flag: '🇭🇹', code: 'ht' },
  { id: 72, label: 'HUF', value: 'HUF', sign: 'Ft', country: 'Hungary', flag: '🇭🇺', code: 'hu' },
  {
    id: 73,
    label: 'IDR',
    value: 'IDR',
    sign: 'Rp  ',
    country: 'Indonesia',
    flag: '🇮🇩',
    code: 'id',
  },
  { id: 74, label: 'ILS', value: 'ILS', sign: '₪', country: 'Israel', flag: '🇮🇱', code: 'il' },
  { id: 76, label: 'INR', value: 'INR', sign: '₹', country: 'India', flag: '🇮🇳', code: 'in' },
  { id: 77, label: 'IQD', value: 'IQD', sign: '$', country: 'Iraq', flag: '🇮🇶', code: 'iq' },
  { id: 78, label: 'IRR', value: 'IRR', sign: '﷼', country: 'Iran', flag: '🇮🇷', code: 'ir' },
  { id: 79, label: 'ISK', value: 'ISK', sign: 'kr  ', country: 'Iceland', flag: '🇮🇸', code: 'is' },
  { id: 81, label: 'JMD', value: 'JMD', sign: 'J$  ', country: 'Jamaica', flag: '🇯🇲', code: 'jm' },
  { id: 82, label: 'JOD', value: 'JOD', sign: '$', country: 'Jordan', flag: '🇯🇴', code: 'jo' },
  { id: 84, label: 'KES', value: 'KES', sign: '$', country: 'Kenya', flag: '🇰🇪', code: 'ke' },
  {
    id: 85,
    label: 'KGS',
    value: 'KGS',
    sign: 'лв  ',
    country: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'kg',
  },
  { id: 86, label: 'KHR', value: 'KHR', sign: '៛', country: 'Cambodia', flag: '🇰🇭', code: 'kh' },
  { id: 87, label: 'KMF', value: 'KMF', sign: '$', country: 'Comoros', flag: '🇰🇲', code: 'km' },
  { id: 88, label: 'KPW', value: 'KPW', sign: '₩', country: 'North Korea', flag: '🇰🇵', code: 'kp' },
  { id: 89, label: 'KRW', value: 'KRW', sign: '₩', country: 'South Korea', flag: '🇰🇷', code: 'kr' },
  { id: 90, label: 'KWD', value: 'KWD', sign: '$', country: 'Kuwait', flag: '🇰🇼', code: 'kw' },
  {
    id: 91,
    label: 'KYD',
    value: 'KYD',
    sign: '$',
    country: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'ky',
  },
  {
    id: 92,
    label: 'KZT',
    value: 'KZT',
    sign: 'лв  ',
    country: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'kz',
  },
  { id: 93, label: 'LAK', value: 'LAK', sign: '₭', country: 'Laos', flag: '🇱🇦', code: 'la' },
  { id: 94, label: 'LBP', value: 'LBP', sign: '£', country: 'Lebanon', flag: '🇱🇧', code: 'lb' },
  { id: 95, label: 'LKR', value: 'LKR', sign: '₨', country: 'Sri Lanka', flag: '🇱🇰', code: 'lk' },
  { id: 96, label: 'LRD', value: 'LRD', sign: '$', country: 'Liberia', flag: '🇱🇷', code: 'lr' },
  { id: 97, label: 'LSL', value: 'LSL', sign: '$', country: 'Lesotho', flag: '🇱🇸', code: 'ls' },
  { id: 98, label: 'LYD', value: 'LYD', sign: '$', country: 'Libya', flag: '🇱🇾', code: 'ly' },
  { id: 99, label: 'MAD', value: 'MAD', sign: 'DH', country: 'Morocco', flag: '🇲🇦', code: 'ma' },
  { id: 100, label: 'MDL', value: 'MDL', sign: '$', country: 'Moldova', flag: '🇲🇩', code: 'md' },
  { id: 101, label: 'MGA', value: 'MGA', sign: '$', country: 'Madagascar', flag: '🇲🇬', code: 'mg' },
  {
    id: 102,
    label: 'MKD',
    value: 'MKD',
    sign: 'ден  ',
    country: 'Macedonia',
    flag: '🇲🇰',
    code: 'mk',
  },
  {
    id: 103,
    label: 'MMK',
    value: 'MMK',
    sign: '$',
    country: 'Myanmar (Burma)',
    flag: '🇲🇲',
    code: 'mm',
  },
  { id: 104, label: 'MNT', value: 'MNT', sign: '₮', country: 'Mongolia', flag: '🇲🇳', code: 'mn' },
  {
    id: 105,
    label: 'MOP',
    value: 'MOP',
    sign: '$',
    country: 'Macau SAR China',
    flag: '🇲🇴',
    code: 'mo',
  },
  { id: 106, label: 'MRO', value: 'MRO', sign: '$', country: 'Mauritania', flag: '🇲🇷', code: 'mr' },
  { id: 108, label: 'MUR', value: 'MUR', sign: '₨', country: 'Mauritius', flag: '🇲🇺', code: 'mu' },
  { id: 109, label: 'MVR', value: 'MVR', sign: '$', country: 'Maldives', flag: '🇲🇻', code: 'mv' },
  { id: 110, label: 'MWK', value: 'MWK', sign: '$', country: 'Malawi', flag: '🇲🇼', code: 'mw' },
  { id: 111, label: 'MXN', value: 'MXN', sign: '$', country: 'Mexico', flag: '🇲🇽', code: 'mx' },
  {
    id: 112,
    label: 'MYR',
    value: 'MYR',
    sign: 'RM  ',
    country: 'Malaysia',
    flag: '🇲🇾',
    code: 'my',
  },
  {
    id: 113,
    label: 'MZN',
    value: 'MZN',
    sign: 'MT  ',
    country: 'Mozambique',
    flag: '🇲🇿',
    code: 'mz',
  },
  { id: 114, label: 'NAD', value: 'NAD', sign: '$', country: 'Namibia', flag: '🇳🇦', code: 'na' },
  { id: 115, label: 'NGN', value: 'NGN', sign: '₦', country: 'Nigeria', flag: '🇳🇬', code: 'ng' },
  {
    id: 116,
    label: 'NIO',
    value: 'NIO',
    sign: 'C$  ',
    country: 'Nicaragua',
    flag: '🇳🇮',
    code: 'ni',
  },
  {
    id: 117,
    label: 'NOK',
    value: 'NOK',
    sign: 'kr  ',
    country: 'Norway Krone',
    flag: '🇳🇴',
    code: 'no',
  },
  { id: 118, label: 'NPR', value: 'NPR', sign: '₨ ', country: 'Nepal', flag: '🇳🇵', code: 'np' },
  { id: 120, label: 'OMR', value: 'OMR', sign: '﷼', country: 'Oman', flag: '🇴🇲', code: 'om' },
  { id: 121, label: 'PAB', value: 'PAB', sign: 'B/.', country: 'Panama', flag: '🇵🇦', code: 'pa' },
  { id: 122, label: 'PEN', value: 'PEN', sign: 'S/. ', country: 'Peru', flag: '🇵🇪', code: 'pe' },
  {
    id: 123,
    label: 'PGK',
    value: 'PGK',
    sign: '$',
    country: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'pg',
  },
  {
    id: 124,
    label: 'PHP',
    value: 'PHP',
    sign: '₱',
    country: 'Philippines',
    flag: '🇵🇭',
    code: 'ph',
  },
  { id: 125, label: 'PKR', value: 'PKR', sign: '₨', country: 'Pakistan', flag: '🇵🇰', code: 'pk' },
  { id: 126, label: 'PLN', value: 'PLN', sign: 'zł', country: 'Poland', flag: '🇵🇱', code: 'pl' },
  { id: 127, label: 'PYG', value: 'PYG', sign: 'Gs ', country: 'Paraguay', flag: '🇵🇾', code: 'py' },
  { id: 128, label: 'QAR', value: 'QAR', sign: '﷼', country: 'Qatar', flag: '🇶🇦', code: 'qa' },
  {
    id: 129,
    label: 'RON',
    value: 'RON',
    sign: 'lei  ',
    country: 'Romania',
    flag: '🇷🇴',
    code: 'ro',
  },
  { id: 130, label: 'RSD', value: 'RSD', sign: 'Дин.', country: 'Serbia', flag: '🇷🇸', code: 'rs' },
  { id: 131, label: 'RUB', value: 'RUB', sign: '₽', country: 'Russia', flag: '🇷🇺', code: 'ru' },
  { id: 132, label: 'RWF', value: 'RWF', sign: '$', country: 'Rwanda', flag: '🇷🇼', code: 'rw' },
  {
    id: 133,
    label: 'SAR',
    value: 'SAR',
    sign: '﷼',
    country: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'sa',
  },
  {
    id: 134,
    label: 'SBD',
    value: 'SBD',
    sign: '$',
    country: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'sb',
  },
  { id: 135, label: 'SCR', value: 'SCR', sign: '₨', country: 'Seychelles', flag: '🇸🇨', code: 'sc' },
  { id: 136, label: 'SDG', value: 'SDG', sign: '$', country: 'Sudan', flag: '🇸🇩', code: 'sd' },
  { id: 137, label: 'SEK', value: 'SEK', sign: 'kr ', country: 'Sweden', flag: '🇸🇪', code: 'se' },
  { id: 138, label: 'SGD', value: 'SGD', sign: '$', country: 'Singapore', flag: '🇸🇬', code: 'sg' },
  { id: 139, label: 'SHP', value: 'SHP', sign: '£', country: 'St. Helena', flag: '🇸🇭', code: 'sh' },
  {
    id: 140,
    label: 'SLL',
    value: 'SLL',
    sign: '$',
    country: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'sl',
  },
  { id: 141, label: 'SOS', value: 'SOS', sign: 'S', country: 'Somalia', flag: '🇸🇴', code: 'so' },
  { id: 142, label: 'SRD', value: 'SRD', sign: '$', country: 'Suriname', flag: '🇸🇷', code: 'sr' },
  {
    id: 144,
    label: 'STD',
    value: 'STD',
    sign: '$',
    country: 'São Tomé & Príncipe',
    flag: '🇸🇹',
    code: 'st',
  },
  { id: 147, label: 'SYP', value: 'SYP', sign: '£', country: 'Syria', flag: '🇸🇾', code: 'sy' },
  { id: 148, label: 'SZL', value: 'SZL', sign: '$', country: 'Swaziland', flag: '🇸🇿', code: 'sz' },
  { id: 149, label: 'THB', value: 'THB', sign: '฿', country: 'Thailand', flag: '🇹🇭', code: 'th' },
  { id: 150, label: 'TJS', value: 'TJS', sign: '$', country: 'Tajikistan', flag: '🇹🇯', code: 'tj' },
  {
    id: 151,
    label: 'TMT',
    value: 'TMT',
    sign: '$',
    country: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'tm',
  },
  { id: 152, label: 'TND', value: 'TND', sign: '$', country: 'Tunisia', flag: '🇹🇳', code: 'tn' },
  { id: 153, label: 'TOP', value: 'TOP', sign: '$', country: 'Tonga', flag: '🇹🇴', code: 'to' },
  { id: 154, label: 'TRY', value: 'TRY', sign: '₺', country: 'Turkey', flag: '🇹🇷', code: 'tr' },
  {
    id: 155,
    label: 'TTD',
    value: 'TTD',
    sign: 'TT$  ',
    country: 'Trinidad & Tobago',
    flag: '🇹🇹',
    code: 'tt',
  },
  { id: 156, label: 'TWD', value: 'TWD', sign: 'NT$  ', country: 'Taiwan', flag: '🇹🇼', code: 'tw' },
  { id: 157, label: 'TZS', value: 'TZS', sign: '$', country: 'Tanzania', flag: '🇹🇿', code: 'tz' },
  { id: 158, label: 'UAH', value: 'UAH', sign: '₴', country: 'Ukraine', flag: '🇺🇦', code: 'ua' },
  { id: 159, label: 'UGX', value: 'UGX', sign: '$', country: 'Uganda', flag: '🇺🇬', code: 'ug' },
  { id: 161, label: 'UYU', value: 'UYU', sign: '$U ', country: 'Uruguay', flag: '🇺🇾', code: 'uy' },
  {
    id: 162,
    label: 'UZS',
    value: 'UZS',
    sign: 'лв ',
    country: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'uz',
  },
  {
    id: 163,
    label: 'VEF',
    value: 'VEF',
    sign: 'Bs ',
    country: 'Venezuela',
    flag: '🇻🇪',
    code: 've',
  },
  { id: 165, label: 'VND', value: 'VND', sign: '₫', country: 'Vietnam', flag: '🇻🇳', code: 'vn' },
  { id: 166, label: 'VUV', value: 'VUV', sign: '$', country: 'Vanuatu', flag: '🇻🇺', code: 'vu' },
  { id: 167, label: 'WST', value: 'WST', sign: '$', country: 'Samoa', flag: '🇼🇸', code: 'ws' },
  { id: 170, label: 'XAF', value: 'XAF', sign: 'FCFA', country: 'CFA franc BEAC', flag: ' ', code: 'xaf' },
  {
    id: 171,
    label: 'XCD',
    value: 'XCD',
    sign: '$',
    country: 'East Caribbean Dollar',
    flag: '🇧🇶',
    code: 'bq',
  },
  { id: 172, label: 'XOF', value: 'XOF', sign: 'CFA', country: 'CFA franc BCEAO', flag: ' ', code: '' },
  { id: 173, label: 'XPF', value: 'XPF', sign: '₣', country: 'Pacific Franc Exchange', flag: ' ', code: '' },
  { id: 177, label: 'YER', value: 'YER', sign: '﷼', country: 'Yemen', flag: '🇾🇪', code: 'ye' },
  {
    id: 178,
    label: 'ZAR',
    value: 'ZAR',
    sign: 'R',
    country: 'South Africa',
    flag: '🇿🇦',
    code: 'za',
  },
  { id: 179, label: 'ZMW', value: 'ZMW', sign: '$', country: 'Zambia', flag: '🇿🇲', code: 'zm' },
  { id: 180, label: 'ZWL', value: 'ZWL', sign: '$', country: 'Zimbabwe', flag: '🇿🇼', code: 'zw' },
];
