import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { string, bool, node } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  SecondaryButton,
  FieldCountNumber,
  FieldSelect,
  FieldTextInput,
  HeadingFormFieldWrapper,
} from '../../components';
import { uid } from 'react-uid';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Modal } from '@mui/material';
import { Box } from '@material-ui/core';

import css from './CheckoutDetailsForm.css';

import { types as sdkTypes } from '../../util/sdkLoader';
import {
  unitDivisor,
  convertUnitToSubUnit,
  convertMoneyToNumber,
  ensureDotSeparator,
  formatMoney,
  getRefCurrencyValue,
} from '../../util/currency';
import { isAfterDate, subtractTime } from 'util/dates';
import moment from 'moment';

import Airplane from '../../assets/CheckoutPage/airplane-308023.svg';
import Bedroom from '../../assets/CheckoutPage/bedroom-308002.svg';
import Knife from '../../assets/CheckoutPage/knife-308063.svg';
import Pass from '../../assets/CheckoutPage/pass-308183.svg';

const textRequired = value => (value ? undefined : 'Required');

const { Money } = sdkTypes;

const getStandardTime = (items, participants) => {
  if (items >= 31 || participants >= 31) return 120;
  else if ((items >= 21 && items <= 30) || (participants >= 16 && participants <= 30)) return 96;
  else if ((items >= 9 && items <= 20) || (participants >= 7 && participants <= 15)) return 72;
  else if (items <= 8 || participants <= 6) return 48;
  else return 48;
};

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currency) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(convertUnitToSubUnit(unformattedValue, unitDivisor(currency)), currency);
  } catch (e) {
    return null;
  }
};

function createSentence(words) {
  const len = words.length;

  if (len === 0) {
    return ''; // Return an empty string for an empty list
  } else if (len === 1) {
    return words[0]; // Return the only word as it is for a list with a single word
  } else {
    // Insert ', ' between all words except the last two
    const sentence = words.slice(0, len - 2).join(', ') + ', ';

    // Add ' and ' between the last two words and append the last word
    return sentence + words.slice(len - 2).join(' and ');
  }
}

const BUDGET_NO_BREAKFAST = 'Budget No Breakfast';
const BUDGET_INCLUDE_BREAKFAST = 'Budget Include Breakfast';
const GOOD_VALUE_NO_BREAKFAST = 'Good value No Breakfast';
const GOOD_VALUE_INCLUDE_BREAKFAST = 'Good value Include Breakfast';
const PREMIUM_NO_BREAKFAST = 'Premium No Breakfast';
const PREMIUM_INCLUDE_BREAKFAST = 'Premium Include Breakfast';
const LUXURY_NO_BREAKFAST = 'Luxury No Breakfast';
const LUXURY_INCLUDE_BREAKFAST = 'Luxury Include Breakfast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 16,
  py: 3,
  px: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const CheckoutDetailsFormComponent = forwardRef((props, ref) => {
  const formRef = useRef(null);
  const { lastTransaction, onPrefillSubmit } = props;
  const tripDataValues = JSON.parse(localStorage.getItem('tripDetails'));
  const isPrefillEligible =
    !tripDataValues?.transportType && !tripDataValues?.accommodationType && lastTransaction
      ? isAfterDate(lastTransaction.attributes.createdAt, subtractTime(new Date(), 7, 'days'))
      : false;
  const tripDetails = lastTransaction?.attributes?.protectedData?.tripDetails || {};

  const {
    tripName,
    tripType = 'Open to Anything',
    estimatedMinBudget,
    estimatedMaxBudget,
    tripLocation,
    precision,
    originLocation,
    targetStartDate,
    targetEndDate,
    timePrecision,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    includeTransport,
    transportType,
    transportPricingType,
    includeAccommodation,
    accommodationType,
    accommodationPricing,
    includeDineInMeals,
    numberOfBreakfast,
    breakfastVenueType,
    numberOfLunch,
    lunchVenueType,
    numberOfDinner,
    dinnerVenueType,
    mealPricing,
    includeActivities,
    researchActivities,
    guidance,
  } = tripDetails;

  const lastNumberOfAdults = numberOfAdults;
  const lastNumberOfChildren = numberOfChildren;
  const lastNumberOfInfants = numberOfInfants;

  // To get conditional variables
  const transportInclude =
    includeTransport && includeTransport.includes('yes') && (transportType || accommodationType);
  const accommodationInclude = includeAccommodation && includeAccommodation.includes('yes');
  const mealsInclude =
    includeDineInMeals &&
    includeDineInMeals.includes('yes') &&
    (numberOfBreakfast || numberOfLunch || numberOfDinner);

  const activitiesInclude = includeActivities && includeActivities.includes('yes');

  const [upgradeModal, setUpgradeModal] = useState(false);
  const [prefillModal, setPrefillModal] = useState(isPrefillEligible);
  const [transWarning, setTransWarning] = useState(false);
  const [accWarning, setAccWarning] = useState(false);
  const [activiWarning, setActiviWarning] = useState(false);
  const [mealVenueWarning, setMealVenueWarning] = useState(false);

  const closeModal = () => {
    setUpgradeModal(false);
    setPrefillModal(false);
  };

  const resetWarningMsg = () => {
    setTransWarning(false);
    setAccWarning(false);
    setActiviWarning(false);
    setMealVenueWarning(false);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current()
      }
    }
  }));

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          listing,
          onUpdatePackage,
          submitButtonWrapperClassName,
          handleSubmit,
          inProgress,
          intl,
          values,
          form,
          onBack,
          onChange,
          packageData,
          currencyConversionRate,
        } = fieldRenderProps;

        const { to = 'USD', rate = 1 } = currencyConversionRate;
        const showRefCurrency = to !== 'USD';

        // Participants
        const numberOfAdults = values.numberOfAdults ?? 0;
        const numberOfChildren = values.numberOfChildren ?? 0;
        const numberOfInfants = values.numberOfInfants ?? 0;
        const numberOfParticipants = numberOfAdults + numberOfChildren + numberOfInfants;

        // Items
        const coefficientOfTransport = values.transportType == 'All' ? 2 : 1;
        const numberOfTransport =
          JSON.stringify(values.includeTransport) == JSON.stringify(['yes']) &&
          values?.transportType
            ? coefficientOfTransport * 2
            : 0;
        const numberOfAccommodation =
          JSON.stringify(values.includeAccommodation) == JSON.stringify(['yes']) &&
          values?.accommodationType
            ? 1
            : 0;
        const numberOfBreakfast = values.numberOfBreakfast ?? 0;
        const numberOfLunch = values.numberOfLunch ?? 0;
        const numberOfDinner = values.numberOfDinner ?? 0;
        const numberOfDine =
          JSON.stringify(values.includeDineInMeals) == JSON.stringify(['yes'])
            ? numberOfBreakfast + numberOfLunch + numberOfDinner
            : 0;
        const numberOfActivities =
          JSON.stringify(values.includeActivities) == JSON.stringify(['yes'])
            ? values.researchActivities?.length ?? 0
            : 0;
        const numberOfItems =
          numberOfTransport + numberOfAccommodation + numberOfDine + numberOfActivities;

        const standardTime = getStandardTime(numberOfItems, numberOfParticipants);

        // Custom Package Data for Upgrading
        const packages = listing.attributes.publicData.packages || [];
        const baseCurrency = listing?.attributes?.publicData?.base_currency || 'USD';
        const customPackageData = packages && packages[2] ? packages[2] : {};
        const itemPrice = new Money(customPackageData?.items?.amount || 0, baseCurrency);
        const participantsPrice = new Money(
          customPackageData?.participants?.amount || 0,
          baseCurrency
        );

        // Total Item Price on Custom
        const itemsPriceInNumber = itemPrice ? convertMoneyToNumber(itemPrice) : 0;
        const totalItemsPrice = numberOfItems * itemsPriceInNumber;
        const formattedItemsPrice = getPrice(
          ensureDotSeparator(totalItemsPrice.toString()),
          baseCurrency
        );
        const formattedItemsPriceString = formatMoney(intl, formattedItemsPrice);

        // Total Participant Price on Custom
        const participantsPriceInNumber = itemPrice ? convertMoneyToNumber(participantsPrice) : 0;
        const totalParticipantsPrice = parseInt(numberOfParticipants) * participantsPriceInNumber;
        const formattedParticipantsPrice = getPrice(
          ensureDotSeparator(totalParticipantsPrice.toString()),
          baseCurrency
        );
        const formattedParticipantsPriceString = formatMoney(intl, formattedParticipantsPrice);

        const TotalItemAndParticipants =
          formattedItemsPrice.amount + formattedParticipantsPrice.amount;
        const grandTotal = new Money(TotalItemAndParticipants, baseCurrency);
        const formattedGrandTotal = formatMoney(intl, grandTotal);
        const formattedRefGrandTotal = getRefCurrencyValue(intl, rate, grandTotal);

        const updatedPackageValues = {
          collaborationTime: { hours: standardTime, percentage: 0 },
          deliveryTime: { hours: standardTime, percentage: 0 },
          items: numberOfItems.toString(),
          itemsUnitPrice: formattedItemsPrice,
          packageLable: 'custom',
          participants: numberOfParticipants.toString(),
          participantsUnitPrice: formattedParticipantsPrice,
          price: grandTotal,
        };

        const wordList = [];

        if (lastNumberOfAdults || lastNumberOfChildren || lastNumberOfInfants) {
          wordList.push('Who');
        }

        if (transportInclude && transportType) {
          wordList.push('Transport');
        }

        if (accommodationInclude && accommodationType) {
          wordList.push('Accommodation');
        }

        if (mealsInclude) {
          wordList.push('Meals');
        }

        if (activitiesInclude && researchActivities) {
          wordList.push('Activity');
        }

        if (guidance) {
          wordList.push('Guidance Information');
        }

        const sentence = createSentence(wordList);

        const {
          mutators: { push, pop },
        } = fieldRenderProps.form;

        //Transport
        const transportPreferredCarrierLabel = intl.formatMessage(
          {
            id: 'CheckoutDetailsForm.transportPreferredCarrierLabelMessage',
          },
          {
            i: chunks => <i style={{ fontWeight: 'normal' }}>{chunks}</i>,
          }
        );
        const transportPreferredCarrierPlaceholder = intl.formatMessage({
          id: 'CheckoutDetailsForm.transportPreferredCarrierPlaceholderMessage',
        });
        const transportRewardsMembershipLabel = intl.formatMessage(
          {
            id: 'CheckoutDetailsForm.transportRewardsMembershipLabelMessage',
          },
          {
            i: chunks => <i style={{ fontWeight: 'normal' }}>{chunks}</i>,
          }
        );
        const transportRewardsMembershipPlaceholder = intl.formatMessage({
          id: 'CheckoutDetailsForm.transportRewardsMembershipPlaceholderMessage',
        });

        //Accommodation
        const accommPreferredBrandLabel = intl.formatMessage(
          {
            id: 'CheckoutDetailsForm.accommPreferredBrandLabelMessage',
          },
          {
            i: chunks => <i style={{ fontWeight: 'normal' }}>{chunks}</i>,
          }
        );
        const accommPreferredBrandPlaceholder = intl.formatMessage({
          id: 'CheckoutDetailsForm.accommPreferredBrandPlaceholderMessage',
        });
        const accommRewardsMembershipLabel = intl.formatMessage(
          {
            id: 'CheckoutDetailsForm.accommRewardsMembershipLabelMessage',
          },
          {
            i: chunks => <i style={{ fontWeight: 'normal' }}>{chunks}</i>,
          }
        );
        const accommRewardsMembershipPlaceholder = intl.formatMessage({
          id: 'CheckoutDetailsForm.accommRewardsMembershipPlaceholderMessage',
        });

        //Who
        const whoLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.whoLabel',
        });
        const whoSupportMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.whoSupport',
        });
        const exceedItemsErrorMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.exceedItemsErrorText',
        });
        const exceedParticipantsErrorMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.exceedParticipantsErrorText',
        });
        const numberAdultsLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberAdultsLabel',
        });
        const numberChildrenLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberChildrenLabel',
        });
        const numberInfantsLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberInfantsLabel',
        });
        const transNotAllowedEmptyLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.transNotAllowedEmpty',
        });
        const accNotAllowedEmptyLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.accNotAllowedEmpty',
        });
        const activiNotAllowedEmptyLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.activiNotAllowedEmpty',
        });
        const mealNotAllowedEmptyLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.mealNotAllowedEmpty',
        });

        //Research Inclusions
        const researchInclusionsLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.researchInclusionsLabel',
        });
        const researchInclusionsSupportMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.researchInclusionsSupport',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const shouldBeUpgraded =
          packageData.participants < numberOfParticipants || packageData.items < numberOfItems;

        const activiSubmitDisabled =
          values?.includeActivities?.includes('yes') && !values?.researchActivities?.every(Boolean);

        const transSubmitDisabled =
          values?.includeTransport?.includes('yes') &&
          (!values?.transportPricingType || !values?.transportType);

        const accSubmitDisabled =
          values?.includeAccommodation?.includes('yes') &&
          (!values?.accommodationPricing || !values?.accommodationType);
        const mealSubmitDisabled =
          values?.includeDineInMeals?.includes('yes') &&
          ((values?.numberOfBreakfast && (!values?.breakfastVenueType || !values?.mealPricing)) ||
            (values?.numberOfLunch && (!values?.lunchVenueType || !values?.mealPricing1)) ||
            (values?.numberOfDinner && (!values?.dinnerVenueType || !values?.mealPricing2)));

        const submitDisabled =
          submitInProgress ||
          activiSubmitDisabled ||
          transSubmitDisabled ||
          accSubmitDisabled ||
          mealSubmitDisabled;

        const onSubmit = e => {
          if (submitDisabled) {
            if(e && typeof e.preventDefault === 'function') {
              e.preventDefault();
            }
            accSubmitDisabled ? setAccWarning(true) : setAccWarning(false);
            transSubmitDisabled ? setTransWarning(true) : setTransWarning(false);
            activiSubmitDisabled ? setActiviWarning(true) : setActiviWarning(false);
            mealSubmitDisabled ? setMealVenueWarning(true) : setMealVenueWarning(false);
          } else if (shouldBeUpgraded) {
            if(e && typeof e.preventDefault === 'function') {
              e.preventDefault();
            }
            resetWarningMsg();
            setUpgradeModal(true);
          } else {
            resetWarningMsg();
            handleSubmit();
          }
        };
        formRef.current = onSubmit;

        return (
          <Form className={classes} onSubmit={onSubmit}>
            <FormSpy onChange={onChange} />

            <HeadingFormFieldWrapper
              label={researchInclusionsLabel}
              supportText={researchInclusionsSupportMessage}
              className={css.sectionTitle}
            />
            {packageData.items < numberOfItems && (
              <p className={css.errorMsg}>{exceedItemsErrorMessage}</p>
            )}

            <div className={css.switch}>
              <div className={css.titleWrapper}>
                <img src={Airplane} alt="airplane icon" width="25px" />
                <h4>
                  <FormattedMessage id="CheckoutDetailsForm.includeTransport" />
                </h4>
              </div>
              <FieldCheckbox
                id={'includeTransport'}
                name={'includeTransport'}
                label={'Yes'}
                value={'yes'}
              />
            </div>

            {transWarning && <p className={css.errorMsg}>{transNotAllowedEmptyLabel}</p>}

            {values.includeTransport && values.includeTransport.includes('yes') && (
              <>
                <FieldSelect
                  className={css.field}
                  type="select"
                  name="transportType"
                  id="transportType"
                  label={intl.formatMessage({
                    id: 'CheckoutDetailsForm.typeIncluded',
                  })}
                >
                  <option value="" disabled>
                    {'Select Transport included in Research'}
                  </option>
                  <option value="Main Only">
                    {'Main Only(Flights, Ferries, Long Bus & Train etc.)'}
                  </option>
                  <option value="All">
                    {'All (Include short transfers, taxis, short trains and bus)'}
                  </option>
                </FieldSelect>

                {values.transportType && (
                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="transportPricingType"
                    id="transportPricingType"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.pricingType',
                    })}
                  >
                    <option value="" disabled>
                      {'Select Travel Category'}
                    </option>
                    <option value="Economy">{'Economy'}</option>
                    <option value="Business">{'Business'}</option>
                    <option value="First Class">{'First Class'}</option>
                  </FieldSelect>
                )}
                {values.transportPricingType && (
                  <FieldTextInput
                    id="transportPreferredCarrier"
                    name="transportPreferredCarrier"
                    className={css.optionalFieldContainer}
                    type="text"
                    label={transportPreferredCarrierLabel}
                    placeholder={transportPreferredCarrierPlaceholder}
                  />
                )}
                {values.transportPreferredCarrier && (
                  <FieldTextInput
                    id="transportRewardsMembership"
                    name="transportRewardsMembership"
                    className={css.optionalFieldContainer}
                    type="text"
                    label={transportRewardsMembershipLabel}
                    placeholder={transportRewardsMembershipPlaceholder}
                  />
                )}
              </>
            )}
            <div style={{ height: 10 }}></div>

            <div className={css.switch}>
              <div className={css.titleWrapper}>
                <img src={Bedroom} alt="bedroom icon" width="25px" />
                <h4>
                  <FormattedMessage id="CheckoutDetailsForm.includeAccommodation" />
                </h4>
              </div>
              <FieldCheckbox
                id={'includeAccommodation'}
                name={'includeAccommodation'}
                label={'Yes'}
                value={'yes'}
              />
            </div>

            {accWarning && <p className={css.errorMsg}>{accNotAllowedEmptyLabel}</p>}

            {values.includeAccommodation && values.includeAccommodation.includes('yes') && (
              <>
                <FieldSelect
                  className={css.field}
                  type="select"
                  name="accommodationType"
                  id="accommodationType"
                  label={intl.formatMessage({
                    id: 'CheckoutDetailsForm.accommodationType',
                  })}
                >
                  <option value="" disabled>
                    {'Select Travel Category'}
                  </option>
                  <option value={BUDGET_NO_BREAKFAST}>{'Budget (No Breakfast)'}</option>
                  <option value={BUDGET_INCLUDE_BREAKFAST}>{'Budget (Include Breakfast)'}</option>
                  <option value={GOOD_VALUE_NO_BREAKFAST}>{'Good Value (No Breakfast)'}</option>
                  <option value={GOOD_VALUE_INCLUDE_BREAKFAST}>
                    {'Good Value (Include Breakfast)'}
                  </option>
                  <option value={PREMIUM_NO_BREAKFAST}>{'Premium (No Breakfast)'}</option>
                  <option value={PREMIUM_INCLUDE_BREAKFAST}>{'Premium (Include Breakfast)'}</option>
                  <option value={LUXURY_NO_BREAKFAST}>{'Luxury (No Breakfast)'}</option>
                  <option value={LUXURY_INCLUDE_BREAKFAST}>{'Luxury (Include Breakfast)'}</option>
                </FieldSelect>

                {values.accommodationType && (
                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="accommodationPricing"
                    id="accommodationPricing"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.pricingEst',
                    })}
                  >
                    <option value="" disabled>
                      {'Select Accommodation Budget'}
                    </option>
                    <option value="$0 - $50 night pp">$0-$50 night pp</option>
                    <option value="$50 - $100 night pp">$50-$100 night pp</option>
                    <option value="$101 - $200 night pp">$101-$200 night pp</option>
                    <option value="$201 - $300 night pp">$201-$300 night pp</option>
                    <option value="$300 - $500 night pp">$300-$500 night pp</option>
                    <option value="$500+ night pp">$500+ night pp</option>
                  </FieldSelect>
                )}
                {values.accommodationPricing && (
                  <FieldTextInput
                    id="accommPreferredBrand"
                    name="accommPreferredBrand"
                    className={css.optionalFieldContainer}
                    type="text"
                    label={accommPreferredBrandLabel}
                    placeholder={accommPreferredBrandPlaceholder}
                  />
                )}
                {values.accommPreferredBrand && (
                  <FieldTextInput
                    id="accommRewardsMembership"
                    name="accommRewardsMembership"
                    className={css.optionalFieldContainer}
                    type="text"
                    label={accommRewardsMembershipLabel}
                    placeholder={accommRewardsMembershipPlaceholder}
                  />
                )}
              </>
            )}
            <div style={{ height: 10 }}></div>

            <div className={css.switch}>
              <div className={css.titleWrapper}>
                <img src={Knife} alt="knife icon" width="25px" />
                <h4>
                  <FormattedMessage id="CheckoutDetailsForm.includeDineMeals" />
                </h4>
              </div>
              <FieldCheckbox
                id={'includeDineInMeals'}
                name={'includeDineInMeals'}
                label={'Yes'}
                value={'yes'}
              />
            </div>

            {mealVenueWarning && <p className={css.errorMsg}>{mealNotAllowedEmptyLabel}</p>}
            {values.includeDineInMeals && values.includeDineInMeals.includes('yes') && (
              <>
                {[
                  BUDGET_NO_BREAKFAST,
                  GOOD_VALUE_NO_BREAKFAST,
                  PREMIUM_NO_BREAKFAST,
                  LUXURY_NO_BREAKFAST,
                ].includes(values.accommodationType) && (
                    <>
                      <FieldCountNumber
                        id="numberOfBreakfast"
                        type="number"
                        name="numberOfBreakfast"
                        className={css.field}
                        form={form}
                        label={intl.formatMessage({
                          id: 'CheckoutDetailsForm.breakfastLabel',
                        })}
                      />

                      {values.numberOfBreakfast > 0 &&
                        <>
                          <FieldSelect
                            className={css.field}
                            type="select"
                            name="breakfastVenueType"
                            id="breakfastVenueType"
                            label={intl.formatMessage({
                              id: 'CheckoutDetailsForm.prefuredVenueTypeLabel',
                            })}
                          >
                            <option value="" disabled>
                              {'Suitable Eating Places'}
                            </option>
                            <option value="Any">Any</option>
                            <option value="Cheap & Basic">Cheap & Basic</option>
                            <option value="Pub & Cafe">Pub & Cafe</option>
                            <option value="Trendy">Trendy</option>
                            <option value="Fine Dining">Fine Dining</option>
                            <option value="Suit Large Group">Suit Large Group</option>
                            <option value="Accommodation venue package (if available)">
                              Accommodation venue package (if available)
                            </option>
                            <option value="Specific Requirement (Message Me)">
                              Specific Requirement (Message Me)
                            </option>
                          </FieldSelect>

                          <FieldSelect
                            className={css.field}
                            type="select"
                            name="mealPricing"
                            id="mealPricing"
                            label={intl.formatMessage({
                              id: 'CheckoutDetailsForm.pricingType',
                            })}
                          >
                            <option value="" disabled>
                              {'Select Est. per meal budget'}
                            </option>
                            <option value={'<$15pp'}>{'<$15pp'} </option>
                            <option value="$16 - $25 meal pp">$16-$25 meal pp</option>
                            <option value="$26 - $40 meal pp">$26-$40 meal pp</option>
                            <option value="$41 - $80 meal pp">$41-$80 meal pp</option>
                            <option value="$81 - $120 meal pp">$81-$120 meal pp</option>
                            <option value="$121 - $200 meal pp">$121-$200 meal pp</option>
                            <option value="$201+ meal pp">$201+ meal pp</option>
                          </FieldSelect>
                        </>
                      }
                    </>
                  )}
                <FieldCountNumber
                  id="numberOfLunch"
                  type="number"
                  name="numberOfLunch"
                  className={css.field}
                  form={form}
                  label={intl.formatMessage({
                    id: 'CheckoutDetailsForm.lunchLabel',
                  })}
                />
                {values.numberOfLunch > 0 && <>
                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="lunchVenueType"
                    id="lunchVenueType"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.prefuredVenueTypeLabel',
                    })}
                  >
                    <option value="" disabled>
                      {'Suitable Eating Places'}
                    </option>
                    <option value="Any">Any</option>
                    <option value="Cheap & Basic">Cheap & Basic</option>
                    <option value="Pub & Cafe">Pub & Cafe</option>
                    <option value="Trendy">Trendy</option>
                    <option value="Fine Dining">Fine Dining</option>
                    <option value="Suit Large Group">Suit Large Group</option>
                    <option value="Accommodation venue package (if available)">
                      Accommodation venue package (if available)
                    </option>
                    <option value="Specific Requirement (Message Me)">
                      Specific Requirement (Message Me)
                    </option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="mealPricing1"
                    id="mealPricing1"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.pricingType',
                    })}
                  >
                    <option value="" disabled>
                      {'Select Est. per meal budget'}
                    </option>
                    <option value={'<$15pp'}>{'<$15pp'} </option>
                    <option value="$16 - $25 meal pp">$16-$25 meal pp</option>
                    <option value="$26 - $40 meal pp">$26-$40 meal pp</option>
                    <option value="$41 - $80 meal pp">$41-$80 meal pp</option>
                    <option value="$81 - $120 meal pp">$81-$120 meal pp</option>
                    <option value="$121 - $200 meal pp">$121-$200 meal pp</option>
                    <option value="$201+ meal pp">$201+ meal pp</option>
                  </FieldSelect>
                </>}

                {/* dinner */}
                <FieldCountNumber
                  id="numberOfDinner"
                  type="number"
                  name="numberOfDinner"
                  className={css.field}
                  form={form}
                  label={intl.formatMessage({
                    id: 'CheckoutDetailsForm.dinnerLabel',
                  })}
                />
                {values.numberOfDinner > 0 && <>
                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="dinnerVenueType"
                    id="dinnerVenueType"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.prefuredVenueTypeLabel',
                    })}
                  >
                    <option value="" disabled>
                      {'Suitable Eating Places'}
                    </option>
                    <option value="Any">Any</option>
                    <option value="Cheap & Basic">Cheap & Basic</option>
                    <option value="Pub & Cafe">Pub & Cafe</option>
                    <option value="Trendy">Trendy</option>
                    <option value="Fine Dining">Fine Dining</option>
                    <option value="Suit Large Group">Suit Large Group</option>
                    <option value="Accommodation venue package (if available)">
                      Accommodation venue package (if available)
                    </option>
                    <option value="Specific Requirement (Message Me)">
                      Specific Requirement (Message Me)
                    </option>
                  </FieldSelect>

                  <FieldSelect
                    className={css.field}
                    type="select"
                    name="mealPricing2"
                    id="mealPricing2"
                    label={intl.formatMessage({
                      id: 'CheckoutDetailsForm.pricingType',
                    })}
                  >
                    <option value="" disabled>
                      {'Select Est. per meal budget'}
                    </option>
                    <option value={'<$15pp'}>{'<$15pp'} </option>
                    <option value="$16 - $25 meal pp">$16-$25 meal pp</option>
                    <option value="$26 - $40 meal pp">$26-$40 meal pp</option>
                    <option value="$41 - $80 meal pp">$41-$80 meal pp</option>
                    <option value="$81 - $120 meal pp">$81-$120 meal pp</option>
                    <option value="$121 - $200 meal pp">$121-$200 meal pp</option>
                    <option value="$201+ meal pp">$201+ meal pp</option>
                  </FieldSelect>
                </>}
              </>
            )}
            <div style={{ height: 10 }}></div>

            <div className={css.switch}>
              <div className={css.titleWrapper}>
                <img src={Pass} alt="pass icon" width="25px" />
                <h4>
                  <FormattedMessage id="CheckoutDetailsForm.includeActivities" />
                </h4>
              </div>
              <FieldCheckbox
                id={'includeActivities'}
                name={'includeActivities'}
                label={'Yes'}
                value={'yes'}
              />
            </div>
            {activiWarning && <p className={css.errorMsg}>{activiNotAllowedEmptyLabel}</p>}
            {values.includeActivities && values.includeActivities.includes('yes') && (
              <>
                <HeadingFormFieldWrapper
                  label={intl.formatMessage({
                    id: 'CheckoutDetailsForm.researchActivities',
                  })}
                  className={css.researchActivities}
                >
                  <FieldArray name={'researchActivities'}>
                    {({ fields }) => {
                      if (fields.length === 0) {
                        fields.push();
                      }
                      return fields.map((name, index) => {
                        if (index < 10) {
                          return (
                            <>
                              <FieldTextInput
                                id="researchActivities"
                                key={uid(`${name}_${index}d`)}
                                className={css.activitiesContainer}
                                name={name}
                                type="text"
                                label={`${index + 1} . `}
                                placeholder={intl.formatMessage({
                                  id: 'CheckoutDetailsForm.enterActivityName',
                                })}
                              />
                            </>
                          );
                        } else {
                          return null;
                        }
                      });
                    }}
                  </FieldArray>
                  <div className={css.mutateBtns}>
                    <SecondaryButton
                      disabled={
                        fieldRenderProps.values.researchActivities &&
                        fieldRenderProps.values.researchActivities.length === 10
                      }
                      type="button"
                      onClick={() => push('researchActivities', undefined)}
                    >
                      +
                    </SecondaryButton>

                    <div className={css.counterValueWrapper}>
                      {values?.researchActivities?.length}
                    </div>

                    <SecondaryButton
                      disabled={
                        !fieldRenderProps.values.researchActivities ||
                        fieldRenderProps.values.researchActivities.length <= 1
                      }
                      type="button"
                      onClick={() => pop('researchActivities')}
                    >
                      -
                    </SecondaryButton>
                  </div>
                </HeadingFormFieldWrapper>
              </>
            )}

            <HeadingFormFieldWrapper
              label={whoLabel}
              supportText={whoSupportMessage}
              className={css.sectionTitle}
            />
            {packageData.participants < numberOfParticipants && (
              <p className={css.errorMsg}>{exceedParticipantsErrorMessage}</p>
            )}
            <FieldCountNumber
              id="numberOfAdults"
              type="number"
              name="numberOfAdults"
              className={css.field}
              defaultValue={1}
              form={form}
              label={numberAdultsLabel}
            />

            <FieldCountNumber
              id="numberOfChildren"
              type="number"
              name="numberOfChildren"
              className={css.field}
              form={form}
              label={numberChildrenLabel}
            />

            <FieldCountNumber
              id="numberOfInfants"
              type="number"
              name="numberOfInfants"
              className={css.field}
              form={form}
              label={numberInfantsLabel}
            />

            <div className={submitButtonWrapperClassName}>
              <PrimaryButton type="button" onClick={onBack}>
                <FormattedMessage id="CheckoutDetailsForm.back" />
              </PrimaryButton>

              <PrimaryButton type="submit" inProgress={submitInProgress}>
                <FormattedMessage id="CheckoutDetailsForm.next" />
              </PrimaryButton>
            </div>
            {
              <Modal open={upgradeModal} onClose={closeModal}>
                <Box sx={{ ...style }} className={css.boxClass}>
                  <div className={css.dialogHeading}>
                    <div className={css.headingWrapper}>
                      {packageData.packageLable !== 'custom' ? (
                        <FormattedMessage
                          id="CheckoutDetailsForm.notCustomUpgradeTitle"
                          values={{
                            packageLable: packageData.packageLable,
                            b: chunks => <strong>{chunks}</strong>,
                            br: <br />,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="CheckoutDetailsForm.customUpgradeTitle"
                          values={{
                            b: chunks => <strong>{chunks}</strong>,
                            br: <br />,
                          }}
                        />
                      )}
                    </div>
                    <div className={css.packageInfoWrapper}>
                      <div className={css.packageTitleWrapper}>
                        <div className={css.packageTitle}>Custom Package (SaaP)</div>
                      </div>
                      <div className={css.packageDetailsWrapper}>
                        <div className={css.lineItem}>
                          <span>
                            Research & Itinerary. <br /> Includes Up To:
                          </span>
                        </div>
                        <div className={css.lineItem}>
                          <span>{numberOfItems} Itinerary Items</span>
                          <span>{formattedItemsPriceString}</span>
                        </div>
                        <div className={css.lineItem}>
                          <span>{numberOfParticipants} Participants</span>
                          <span>{formattedParticipantsPriceString}</span>
                        </div>
                        <div className={css.lineItem}>
                          <span>{standardTime}hr Delivery Time</span>
                          <span>USD 0.00</span>
                        </div>
                        <div className={css.lineItem}>
                          <span>{standardTime}hr collaborationTime</span>
                          <span>USD 0.00</span>
                        </div>
                        <hr className={css.totalDivider} />
                        <div className={css.lineItem}>
                          <div className={css.totalLabel}>
                            <FormattedMessage id="OrderBreakdown.total" />
                          </div>
                          <div className={css.modalTotalPrice}>{formattedGrandTotal}</div>
                        </div>
                        {showRefCurrency && <div className={css.refLineItem}>
                          {`Est. ~${to} ${formattedRefGrandTotal}`}
                        </div>}
                      </div>
                    </div>
                    <div className={css.buttonsWrapper}>
                      <SecondaryButton onClick={closeModal}>Back</SecondaryButton>
                      <PrimaryButton
                        onClick={() => {
                          onUpdatePackage(updatedPackageValues);
                          setUpgradeModal(false);
                          handleSubmit();
                        }}
                      >
                        Agree
                      </PrimaryButton>
                    </div>
                  </div>
                </Box>
              </Modal>
            }
            {
              <Modal open={prefillModal} onClose={closeModal}>
                <Box sx={{ ...style }} className={css.boxClass}>
                  <div className={css.dialogHeading}>
                    <div className={css.prefillHeadingWrapper}>
                      <FormattedMessage
                        id="CheckoutDetailsForm.prefillTitle"
                        values={{
                          b: chunks => <strong>{chunks}</strong>,
                        }}
                      />
                    </div>
                    <div className={css.packageInfoWrapper}>
                      <div className={css.packageTitleWrapper}>
                        <div className={css.packageTitle}>Request Summary</div>
                      </div>
                      <div className={css.packageDetailsWrapper}>
                        {tripName && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.what" />
                            </span>
                            <div className={css.summaryDetails}>
                              <FormattedMessage
                                id="TripSummary.budget"
                                values={{
                                  tripName,
                                  tripType,
                                  minAmount: estimatedMinBudget
                                    ? formatMoney(
                                        intl,
                                        new Money(
                                          estimatedMinBudget.amount,
                                          estimatedMinBudget.currency
                                        )
                                      )
                                    : 'USD 0.00',
                                  maxAmount: estimatedMaxBudget
                                    ? formatMoney(
                                        intl,
                                        new Money(
                                          estimatedMaxBudget.amount,
                                          estimatedMaxBudget.currency
                                        )
                                      )
                                    : 'USD 0.00',
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {tripLocation?.search && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.where" />
                            </span>
                            <div className={css.summaryDetails}>
                              <FormattedMessage
                                id="TripSummary.tripLocation"
                                values={{
                                  tripLocation: tripLocation?.search,
                                  originLocation: originLocation?.search,
                                  precision,
                                  br: <br />,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {targetStartDate && targetEndDate && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.when" />
                            </span>
                            <div className={css.summaryDetails}>
                              <span>{`From: ${moment(new Date(targetStartDate)).format(
                                'ddd Do MMM HA'
                              )}`}</span>
                              <span>{`To: ${moment(new Date(targetEndDate)).format(
                                'ddd Do MMM HA'
                              )}`}</span>
                              <span>{`Precision: ${timePrecision}`}</span>
                            </div>
                          </div>
                        )}
                        <div className={css.summaryWrapper}>
                          <span className={css.summaryDetails}>
                            <FormattedMessage
                              id="TripSummary.inclusion"
                              values={{
                                missingInfo: sentence,
                                br: <br />,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={css.buttonsWrapper}>
                      <SecondaryButton onClick={closeModal}>No Thanks</SecondaryButton>
                      <PrimaryButton
                        onClick={() => {
                          setPrefillModal(false);
                          onPrefillSubmit();
                        }}
                      >
                        Pre-Fill Details
                      </PrimaryButton>
                    </div>
                  </div>
                </Box>
              </Modal>
            }
          </Form>
        );
      }}
    />
  );
});

CheckoutDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

CheckoutDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckoutDetailsForm = CheckoutDetailsFormComponent;

CheckoutDetailsForm.displayName = 'CheckoutDetailsForm';

export default CheckoutDetailsForm;
