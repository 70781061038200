import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash'
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const { priceAddons, base_currency } = currentListing.attributes.publicData;
  const initAddonsValues = priceAddons
    ? priceAddons.map(i => ({
      label: i.label,
      price: new Money(i.amount, i.currency),
    }))
    : null;

  const { packages } = currentListing.attributes.publicData;
  // const packagesValues =
  //   packages && packages.length > 0
  //     ? packages
  //       .filter(function (p) {
  //         return p.sesssions != null || p.price != null;
  //       })
  //       .map(p => ({ ...p, price: new Money(p.price, config.currency) }))
  //     : null;

  const packagesValues =
    packages && packages.length > 0
      ? packages
        .map((p, index) => {

          if (!p.price) {
            p.items = new Money(p.items.amount, p.items.currency);
            p.participants = new Money(p.participants.amount, p.participants.currency);
          }
          else {
            p.price = new Money(p.price.amount, p.price.currency)
          }
          if (!p.tripLength && index === 0) {
            p.tripLength = [3, 7];
          }
          if (!p.tripLength && index === 1) {
            p.tripLength = [5, 21];
          }
          return (p);
        })
      : [
        {tripLength: [3, 7]},
        {tripLength: [5, 21]},
      ];

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        base_currency,
        packages: packagesValues,
      }}
      onSubmit={values => {
        const { base_currency, packages } = values;
        const price = packages[0].price;
        const packagesAttr = packages.map((p) => {

          if (p.items._sdkType === "Money") {
            p.items = {
              amount: p.items.amount,
              currency: p.items.currency
            }
            p.participants = {
              amount: p.participants.amount,
              currency: p.participants.currency
            }

            delete p.collaborationTime
            delete p.deliveryTime
          }
          else {

            const price = {
              amount: p.price.amount,
              currency: p.price.currency
            }
            delete p.price
            p.price = price;
          }
          return (p);
        })

        const updatedPackageValue = cloneDeep(packagesAttr);
        const updateValues = {
          price: price,
          publicData: { base_currency, packages: updatedPackageValue },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  );

  return (
    // <div className={classes}>
    <div className={css.scope}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
